import { defineStore } from 'pinia';
import Config from '@/config';
import { NOTIFICATION_TYPE } from '@/types/user';
import { createNotification } from '@/stores/user/notifications';
import { useUserStore } from '@/stores/user/user';
import { RespError } from '@/types/general';
import { UpdateLeverageData } from '@/types/orders';
import { createRequestData } from '@/utilities';
import {
  InstrumentType, InstrumentState, InstrumentServerResp, InstrumentsServerResp, InstrumentTypeServer,
} from '@/types/instruments';

function convertServerInstrumentToClientInstrument(instrument: InstrumentTypeServer): InstrumentType {
  return new InstrumentType(
    instrument.symbol,
    instrument.consistentSymbol,
    instrument.baseAsset,
    instrument.quoteAsset,
    instrument.minQuantity,
    instrument.maxQuantity,
    instrument.tickSize,
    instrument.tickPrecision,
    instrument.quantitySize,
    instrument.quantityPrecision,
    instrument.released,
    instrument.expiry,
    instrument.open,
    instrument.leverage,
    instrument.maxLeverage,
    instrument.crossMargin,
    instrument.takerFee,
    instrument.isInverse,
    instrument.isQuanto,
    instrument.valueMultiplier,
    instrument.lotMultiplier,
    instrument.marginAsset,
    instrument.quantityAsset,
  );
}

export const useInstrumentsStore = defineStore('instruments', {
  state: (): InstrumentState => ({
    instruments: {},
    nameMappings: {}, // for consistent names => actual names
    assetMapping: {}, // e.g. BTC => {USDT => true, ...} (BTC-USDT)
    cachedInstruments: {},
    instrument: null,
  }),
  actions: {
    setInstrument({ exchangeName, exchangeType, symbol, instrument }: InstrumentServerResp) {
      if (!(exchangeName in this.instruments)) {
        this.instruments[exchangeName] = {};
        this.nameMappings[exchangeName] = {};
        this.assetMapping[exchangeName] = {};
      }

      if (!(exchangeType in this.instruments[exchangeName])) {
        this.instruments[exchangeName][exchangeType] = {};
        this.nameMappings[exchangeName][exchangeType] = {};
        this.assetMapping[exchangeName][exchangeType] = {};
      }

      const newInstrument = convertServerInstrumentToClientInstrument(instrument);

      if (!(symbol in this.instruments[exchangeName][exchangeType])) {
        const [baseAsset, quoteAsset] = instrument.consistentSymbol.split('-');

        if (!(instrument.consistentSymbol in this.nameMappings[exchangeName][exchangeType])) {
          this.nameMappings[exchangeName][exchangeType][instrument.consistentSymbol] = [];
        }

        this.nameMappings[exchangeName][exchangeType][instrument.consistentSymbol].push(symbol);

        if (!(baseAsset in this.assetMapping[exchangeName][exchangeType])) {
          this.assetMapping[exchangeName][exchangeType][baseAsset] = {};
        }

        this.assetMapping[exchangeName][exchangeType][baseAsset][quoteAsset] = true;

        if (!(quoteAsset in this.assetMapping[exchangeName][exchangeType])) {
          this.assetMapping[exchangeName][exchangeType][quoteAsset] = {};
        }

        this.assetMapping[exchangeName][exchangeType][quoteAsset][baseAsset] = true;
      }

      // TODO: Don't always do this - only do it for instrument adding or updating
      this.instruments[exchangeName][exchangeType][symbol] = newInstrument;
    },
    addInstruments(body: InstrumentsServerResp) {
      for (const symbol in body.instruments) {
        this.setInstrument({
          exchangeName: body.exchangeName,
          exchangeType: body.exchangeType,
          symbol,
          instrument: body.instruments[symbol],
        });
      }
    },
    addInstrument(body: InstrumentServerResp) {
      this.setInstrument({
        exchangeName: body.exchangeName,
        exchangeType: body.exchangeType,
        symbol: body.instrument.symbol,
        instrument: body.instrument,
      });
    },
    updateInstrument(body: InstrumentServerResp) {
      this.setInstrument({
        exchangeName: body.exchangeName,
        exchangeType: body.exchangeType,
        symbol: body.instrument.symbol,
        instrument: body.instrument,
      });
    },
    async updateInstrumentLeverage(body: UpdateLeverageData) {
      const leverageData = body.leverageData;
      const requestInfo = createRequestData('POST', body.userToken, leverageData);
      const accountMeta = leverageData.accountMeta;

      try {
        const resp = await fetch(
          `${Config.apiEndpoint()}/exchanges/${accountMeta.exchangeName}/accounts/${accountMeta.accountId}/leverage`,
          requestInfo,
        );

        if (resp.status == 200) {
          const msg = `${leverageData.symbol} leverage successfully updated to  ${leverageData.leverage}`;
          createNotification(msg, NOTIFICATION_TYPE.SUCCESS);
          return resp;
        } else {
          const jsonResp = await resp.json() as RespError;
          const msg = `${leverageData.symbol} leverage failed to update, error: ${jsonResp.error}`;
          throw new Error(msg);
        }
      } catch (error: unknown) {
        const typedError = error as RespError;
        createNotification(typedError.message, NOTIFICATION_TYPE.ERROR);
        return Promise.reject(typedError);
      }
    },
    async fetchInstrument(exchangeName: string, symbol: string) {
      const key = `${exchangeName}+${symbol}`;

      if (key in this.cachedInstruments) {
        this.instrument = this.cachedInstruments[key];
        return this.instrument;
      }

      const userStore = useUserStore();
      const requestInfo = createRequestData('GET', userStore.token, '');

      try {
        const resp = await fetch(
          `${Config.apiEndpoint()}/exchanges/${exchangeName}/exchangeTypes/Derivatives/instruments?symbol=${symbol}`,
          requestInfo,
        );

        if (resp.status == 200) {
          const jsonResp = await resp.json() as { instrument: InstrumentTypeServer };
          this.cachedInstruments[key] = convertServerInstrumentToClientInstrument(jsonResp.instrument);
          this.instrument = this.cachedInstruments[key];
          const msg = `${symbol} successfully fetched for exchange ${exchangeName}`;
          createNotification(msg, NOTIFICATION_TYPE.SUCCESS);
          return this.instrument;
        } else {
          const jsonResp = await resp.json() as RespError;
          const msg = `Exchange ${exchangeName} failed to create, error: ${jsonResp.error}`;
          throw new Error(msg);
        }
      } catch (error: unknown) {
        const typedError = error as RespError;
        createNotification(typedError.message, NOTIFICATION_TYPE.ERROR);
        return Promise.reject(typedError);
      }
    },
    async searchInstruments(exchangeName: string, symbolSearch: string) {
      const userStore = useUserStore();
      const requestInfo = createRequestData('GET', userStore.token, '');

      try {
        const resp = await fetch(
          `${Config.apiEndpoint()}/exchanges/${exchangeName}/exchangeTypes/Derivatives/searchInstruments`
          + `?symbol=${symbolSearch}`,
          requestInfo,
        );

        if (resp.status == 200) {
          const jsonResp = await resp.json() as { instruments: Record<string, InstrumentTypeServer> };
          const instruments = [];

          for (const name in jsonResp.instruments) {
            const instrument = jsonResp.instruments[name];

            instruments.push(
              convertServerInstrumentToClientInstrument(instrument),
            );
          }

          return instruments;
        } else {
          const jsonResp = await resp.json() as RespError;
          const msg = `Exchange ${exchangeName} failed to search symbol ${symbolSearch}, error: ${jsonResp.error}`;
          throw new Error(msg);
        }
      } catch (error: unknown) {
        const typedError = error as RespError;
        createNotification(typedError.message, NOTIFICATION_TYPE.ERROR);
        return [];
      }
    },
  },
  getters: {
    getAssetMapping: (state) => () => state.assetMapping,
    getAll: (state) => () => state.instruments,
    getInstrument: (state) => (exchangeName: string, exchangeType: string, symbol: string) => {
      return state.instruments?.[exchangeName]?.[exchangeType]?.[symbol];
    },
    mapName: (state) => (exchangeName: string, exchangeType: string, symbol: string) => {
      return state.nameMappings?.[exchangeName]?.[exchangeType]?.[symbol];
    },
  },
});
