import { defineStore } from 'pinia';
import { SIDE } from '@/types/account';
import { ExchangeTypeSettings, ExchangeSettings, ExchangesSettingsState } from '@/types/settings';
import { EXCHANGE_NAME, EXCHANGE_TYPE } from '@/types/exchange';
import { useClientLogsStore } from '@/stores/user/clientLogs';

const defaultExchangeSettings: ExchangeSettings = {
};

const defaultExchangeTypeSettings: ExchangeTypeSettings = {
  dynamicInstrumentFetching: false,
  pnlCalc: pnlCalc,
  orderCurrency: '',
  positionCurrency: '',
  showChartVolumeDefault: true,
  fetchChartHistory: true,
};

function pnlCalc(fromPrice: string, toPrice: string, quantity: string, side: SIDE): string {
  const pnl = Math.abs((+fromPrice - +toPrice) * +quantity);

  if (side === SIDE.BUY && +fromPrice > +toPrice) {
    return String(-pnl);
  }

  if (side === SIDE.SELL && +fromPrice < +toPrice) {
    return String(-pnl);
  }

  return String(pnl);
}

// const defaultExchangeInfoSettings = Object.assign(
//   new ExchangeSettings(), {
//     spot: Object.assign(
//       new ExchangeInfoSettings(),
//       {
//         //,
//       },
//     ),
//     derivatives: Object.assign(
//       new ExchangeInfoSettings(),
//       {
//         //,
//       },
//     ),
//   },
// );

export const useExchangesSettingsStore = defineStore('exchangesSettings', {
  state: (): ExchangesSettingsState => ({
    exchangesSettings: {},
  }),
  actions: {
    createExchangeSettings(exchangeName: string): void {
      const exchangeSettings = this.getDefaultExchangeSettings();

      // Apply overrides for certain exchanges
      switch (exchangeName) {
      case EXCHANGE_NAME.CAPITAL:
        this.overrideCapitalSettings(exchangeSettings);
        break;
      case EXCHANGE_NAME.IG:
        this.overrideIgSettings(exchangeSettings);
        break;
      case EXCHANGE_NAME.TEST:
        this.overrideTestSettings(exchangeSettings);
        break;
      }

      this.exchangesSettings[exchangeName] = exchangeSettings;
    },
    getExchangeSettings(exchangeName: string, logStackOffset = 1): ExchangeSettings | null {
      if (!this.exchangesSettings[exchangeName]) {
        if (!(Object.values(EXCHANGE_NAME).includes(exchangeName as EXCHANGE_NAME))) {
          useClientLogsStore().errorLog(`[${exchangeName}] Invalid exchange name specified`, logStackOffset);
          return null;
        }

        this.createExchangeSettings(exchangeName);
      }

      return this.exchangesSettings[exchangeName];
    },
    getExchangeInfoSettings(exchangeName: string, exchangeType: string): ExchangeTypeSettings | null {
      const exchangeSettings = this.getExchangeSettings(exchangeName, 2);

      if (exchangeSettings === null) {
        return null;
      }

      switch (exchangeType) {
      case EXCHANGE_TYPE.SPOT:
        return exchangeSettings.spot;
      case EXCHANGE_TYPE.DERIVATIVES:
        return exchangeSettings.derivatives;
      default:
        useClientLogsStore().errorLog(`[${exchangeName}][${exchangeType}] Invalid exchange type specified`, 1);
      }

      return null;
    },
    overrideCapitalSettings(exchangesSettings: ExchangeSettings): void {
      exchangesSettings.spot = null; // Explicitly nullify to highlight bugs (should never access this for Capital)
      exchangesSettings.derivatives.dynamicInstrumentFetching = true;
      exchangesSettings.derivatives.orderCurrency = '£';
      exchangesSettings.derivatives.positionCurrency = '£';
      exchangesSettings.derivatives.showChartVolumeDefault = false; // No way to get volume (especially realtime)
      exchangesSettings.derivatives.fetchChartHistory = true;
    },
    overrideIgSettings(exchangesSettings: ExchangeSettings): void {
      exchangesSettings.spot = null; // Explicitly nullify to highlight bugs (should never access this for IG)
      exchangesSettings.derivatives.dynamicInstrumentFetching = true;
      exchangesSettings.derivatives.orderCurrency = '£';
      exchangesSettings.derivatives.positionCurrency = '£';
      exchangesSettings.derivatives.showChartVolumeDefault = false; // No way to get volume (especially realtime)
      exchangesSettings.derivatives.fetchChartHistory = false; // Must be false for IG due to very low API limits
    },
    overrideTestSettings(exchangesSettings: ExchangeSettings): void {
      exchangesSettings.spot.fetchChartHistory = false; // Needs implementing still
      exchangesSettings.derivatives.fetchChartHistory = false; // Needs implementing still
    },
  },
  getters: {
    getDefaultExchangeSettings: () => (): ExchangeSettings => {
      const exchangeSettings = Object.assign(new ExchangeSettings(), defaultExchangeSettings);

      exchangeSettings.spot = Object.assign(new ExchangeTypeSettings(), defaultExchangeTypeSettings);
      exchangeSettings.derivatives = Object.assign(new ExchangeTypeSettings(), defaultExchangeTypeSettings);

      return exchangeSettings;
    },
  },
});
