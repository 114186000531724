<template>
  <header id="main-navbar" class="main-header">
    <button class="mobile-menu-button" title="Open Menu" @click="toggleMenu()">
      <i class="ti-menu" />
    </button>

    <nav class="main-header__nav nav" :class="showMobileMenu ? 'nav--mobile-open' : ''" @click="toggleMenu()">
      <li class="nav__item" :class="$route.path.includes('home') ? 'nav__item--active' : ''">
        <router-link class="nav__item__title" to="/home">
          Home
        </router-link>
      </li>
      <li class="nav__item" :class="$route.path.includes('exchanges') ? 'nav__item--active' : ''">
        <router-link class="nav__item__title" to="/exchanges">
          Exchanges
        </router-link>
        <nav class="nav__item__sub">
          <router-link
            class="nav__item__link"
            :class="$route.path == '/exchanges' ? 'nav__item__link--active' : ''"
            to="/exchanges"
          >
            Overview
          </router-link>
          <router-link
            class="nav__item__link"
            :class="$route.path.includes('/exchanges/instruments') ? 'nav__item__link--active' : ''"
            to="/exchanges/instruments"
          >
            Instruments
          </router-link>
          <router-link
            class="nav__item__link"
            :class="$route.path.includes('/exchanges/positions') ? 'nav__item__link--active' : ''"
            to="/exchanges/positions"
          >
            Positions
          </router-link>
          <router-link
            class="nav__item__link"
            :class="$route.path.includes('/exchanges/orders') ? 'nav__item__link--active' : ''"
            to="/exchanges/orders"
          >
            Orders
          </router-link>
          <router-link
            class="nav__item__link"
            :class="$route.path.includes('/exchanges/order_strategies') ? 'nav__item__link--active' : ''"
            to="/exchanges/order_strategies"
          >
            Order Strategies
          </router-link>
          <router-link
            class="nav__item__link"
            :class="$route.path.includes('/exchanges/portfolio') ? 'nav__item__link--active' : ''"
            to="/exchanges/portfolio"
          >
            Portfolio
          </router-link>
        </nav>
      </li>
      <li class="nav__item" :class="$route.path.includes('analysis') ? 'nav__item--active' : ''">
        <router-link class="nav__item__title" :to="{ path: `/analysis` }">
          Analysis
        </router-link>
        <nav class="nav__item__sub">
          <router-link
            class="nav__item__link"
            :class="$route.path.includes('/analysis/arbitrage') ? 'nav__item__link--active' : ''"
            :to="{ path: `/analysis/arbitrage` }"
          >
            Arbitrage
          </router-link>
          <router-link
            class="nav__item__link"
            :class="$route.path.includes('/analysis/orderflow') ? 'nav__item__link--active' : ''"
            :to="{ path: `/analysis/orderflow` }"
          >
            Orderflow
          </router-link>
          <router-link
            class="nav__item__link"
            :class="$route.path.includes('/analysis/paircoverage') ? 'nav__item__link--active' : ''"
            :to="{ path: `/analysis/paircoverage` }"
          >
            Pair Coverage
          </router-link>
          <router-link
            class="nav__item__link"
            :class="$route.path.includes('/analysis/pairliquidity') ? 'nav__item__link--active' : ''"
            :to="{ path: `/analysis/pairliquidity` }"
          >
            Pair Liquidity
          </router-link>
          <router-link
            class="nav__item__link"
            :class="$route.path.includes('/analysis/priceaction') ? 'nav__item__link--active' : ''"
            :to="{ path: `/analysis/priceaction` }"
          >
            Price Action
          </router-link>
          <router-link
            class="nav__item__link"
            :class="$route.path.includes('/analysis/tradingview') ? 'nav__item__link--active' : ''"
            :to="{ path: `/analysis/tradingview` }"
          >
            TradingView
          </router-link>
          <router-link
            class="nav__item__link"
            :class="$route.path.includes('/analysis/funding') ? 'nav__item__link--active' : ''"
            :to="{ path: `/analysis/funding` }"
          >
            Funding Analysis
          </router-link>
          <router-link
            class="nav__item__link"
            :class="$route.path.includes('/analysis/ig_trades') ? 'nav__item__link--active' : ''"
            :to="{ path: `/analysis/ig_trades` }"
          >
            IG Trades Analysis
          </router-link>
        </nav>
      </li>

      <li class="nav__item" :class="$route.path.includes('resources') ? 'nav__item--active' : ''">
        <router-link class="nav__item__title" to="/resources/categories">
          Resources
        </router-link>

        <nav class="nav__item__sub">
          <router-link
            class="nav__item__link"
            :class="$route.path.includes('/resources/categories') ? 'nav__item__link--active' : ''"
            to="/resources/categories"
          >
            Categories
          </router-link>
        </nav>
      </li>
    </nav>

    <div class="main-header__right">
      <div id="dropdown-status" class="dropdown dropdown--main-header  dropdown--last">
        <div
          class="dropdown__combobox dropdown__combobox--status"
          :data-socket-connected="ws.status === WS_STATUS.CONNECTED ? 'true' : 'false'"
          :data-server-connected="!ws.serverEncrypted ? 'true' : 'false'"
          :data-socket-authorised="ws.authorised ? 'true' : 'false'"
          :data-server-ws-activity="monitoring.error"
          role="combobox"
          tabindex="0"
        >
          <i class="ti-cloud" />
        </div>
        <div class="dropdown__menu dropdown__menu--columns">
          <ul
            id="dropdown-status-list"
            class="dropdown__menu__column dropdown__menu__column--border dropdown__menu__column--small"
            role="listbox"
          >
            <li class="dropdown__menu__item dropdown__menu__item--heading">
              Client Status
            </li>
            <li class="dropdown__menu__item" :class="ws.status === WS_STATUS.CONNECTED ? 'green' : 'red'">
              {{ ws.status }}
            </li>
            <li class="dropdown__menu__item" :class="ws.authorised ? 'green' : 'red'">
              {{ ws.authorised ? 'Authorised' : 'Not authorised' }}
            </li>

            <li class="dropdown__menu__item dropdown__menu__item--heading">
              Server Status
            </li>
            <li
              v-if="ws.serverEncrypted"
              class="dropdown-item dropdown__menu__item dropdown__menu__item--form"
            >
              <input v-model="decryptPws" type="password" class="text-black" placeholder="Decryption password..." />
              <button class="btn" @click="userDecryption()">
                Decrypt Server
              </button>
            </li>
            <li v-else class="dropdown-item dropdown__menu__item green">
              Data decrypted
            </li>
            <li class="dropdown__menu__item dropdown__menu__item--heading">
              Trading Session
            </li>
            <li
              v-if="userStore.tradingSessionExpiry !== 0"
              class="dropdown-item dropdown__menu__item dropdown__menu__item--form"
            >
              <div v-if="userStore.tradingSessionExpiry <= new Date().getTime() / 1000">
                Session expired
              </div>
              <div v-else>
                Session expires in:
                {{ formatTimeRemaining(userStore.tradingSessionExpiry - new Date().getTime() / 1000) }}
              </div>
            </li>
            <li
              class="dropdown-item dropdown__menu__item dropdown__menu__item--form"
            >
              <input
                v-model="tradingSessionPws"
                type="password" class="text-black" placeholder="trading password..." autocomplete="off"
              />
              TTL (minutes): <input v-model="tradingSessionTtl" type="number" class="text-black" step="1" />
              <button class="btn" @click="setTradingSession()">
                Set Trading Session
              </button>
            </li>
            <li
              v-if="userStore.tradingSessionExpiry !== 0"
              class="dropdown-item dropdown__menu__item dropdown__menu__item--form"
            >
              <button class="btn" @click="clearTradingSession()">
                Clear Trading Session
              </button>
            </li>
          </ul>
          <ul id="dropdown-ws-activity-list" class="dropdown__menu__column" role="listbox">
            <li class="dropdown__menu__item dropdown__menu__item--heading">
              Websocket Activity
            </li>
            <li v-for="(wsActivity, i) of monitoring.items" :key="i" class="dropdown__menu__item">
              {{ wsActivity.exchangeName }} {{ wsActivity.wsType }}:
              <span :class="wsActivity.secondsAgo > 20 ? 'red-text' : 'green-text'">
                {{ wsActivity.lastUpdateTime }}
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div id="dropdown-profile" class="dropdown dropdown--main-header  dropdown--last" tabindex="0">
        <div class="dropdown__combobox dropdown__combobox--profile" role="combobox">
          <i class="ti-user" :class="{ 'red-text': settingsWarnings > 0 }" />
        </div>
        <ul id="dropdown-profile-list" class="dropdown__menu dropdown__menu--user-settings" role="listbox">
          <li class="dropdown__menu__item dropdown__menu__item--select">
            <label for="chosenAsset">Asset: </label>
            <select id="chosenAsset" v-model="chosenAsset">
              <option v-for="asset in chosenAssets" :key="asset">
                {{ asset }}
              </option>
            </select>
          </li>
          <li class="dropdown__menu__item dropdown__menu__item--link">
            <router-link to="/exchanges/wallets">
              Balance: {{ userSettingsStore.getChosenAssetSymbolWithAmount(formatNumber(String(totalBalance))) }}
            </router-link>
          </li>
          <li class="dropdown__menu__item dropdown__menu__item--link">
            <router-link to="/settings/account-management">
              Settings
              <span v-if="settingsWarnings > 0" class="bg-red-600 text-white text-sm font-bold px-2 py-1 rounded-full">
                {{ settingsWarnings }}
              </span>
            </router-link>
          </li>

          <li class="dropdown__menu__item dropdown__menu__item--link">
            <router-link to="/notifications">
              Notifications
            </router-link>
          </li>

          <li class="dropdown__menu__item dropdown__menu__item--link">
            <router-link to="/logs">
              Logs
            </router-link>
          </li>

          <li class="dropdown__menu__item dropdown__menu__item--link">
            <button @click="logoutUser()">
              Logout
            </button>
          </li>

          <li class="dropdown__menu__item dropdown__menu__item--link">
            <button v-if="resourcesLoggedIn" @click="logoutResourcesUser()">
              Logout Resources
            </button>

            <button v-else @click="loginResourcesUser()">
              Login Resources
            </button>
          </li>
        </ul>
      </div>
      <NotificationDrawer />
      <NotificationPopup />
    </div>
  </header>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useAccountManagementStore } from '@/stores/exchanges/accountManagement';
import { useMonitoringStore } from '@/stores/exchanges/monitoring';
import { usePricesStore } from '@/stores/exchanges/prices';
import { useWalletsStore } from '@/stores/exchanges/wallets';
import { useUserSettingsStore } from '@/stores/user/settings';
import { useUserStore } from '@/stores/user/user';
import { useWebSocketStore } from '@/stores/user/ws';
import { useResourcesStore } from '@/stores/resources/resources';
import { checkPwExpired, formatNumber, formatTimeRemaining } from '@/utilities';
import NotificationPopup from '@/layout/notifications/NotificationPopup.vue';
import NotificationDrawer from '@/layout/notifications/NotificationDrawer.vue';
import { WS_STATUS, TradingSessionResp } from '@/types/user';
import { MonitoringType, MonitoringTypeItem } from '@/types/monitoring';

const router = useRouter();

// Stores
const walletsStore = useWalletsStore();
const userSettingsStore = useUserSettingsStore();
const monitoringStore = useMonitoringStore();
const pricesStore = usePricesStore();
const accountManagementStore = useAccountManagementStore();
const userStore = useUserStore();
const wsStore = useWebSocketStore();
const resourcesStore = useResourcesStore();

// Properties
const showMobileMenu = ref(false);
const decryptPws = ref('');
const tradingSessionPws = ref('');
const tradingSessionTtl = ref('1');
const chosenAsset = ref('');
const totalBalance = ref(0);
const walletsDataCacheTime = ref(0);
const walletsUpdateThrottleTime = ref(20000);
const monitoring = ref(new MonitoringType(false, []));

// Computed Properties
const resourcesLoggedIn = computed(() => resourcesStore.isLoggedIn);
const userPasswordsTs = computed(() => userStore.passwordsTs);
const user2AuthEnabled = computed(() => userStore.user2AuthEnabled);
const ws = computed(() => wsStore.ws);
const wallets = computed(() => walletsStore.wallets);
const prices = computed(() => pricesStore.prices);
const wsActivities = computed(() => monitoringStore.data.wsActivities);
const chosenAssets = computed(() => userSettingsStore.getChosenAssetList);
const currentChosenAsset = computed(() => userSettingsStore.getCurrentChosenAsset);
const systemWarnings = computed(() => {
  let warnings = 0;
  const passwordsts = userPasswordsTs.value;

  if (passwordsts.encrypt && checkPwExpired(passwordsts.encrypt)) warnings++;
  if (passwordsts.firestore && checkPwExpired(passwordsts.firestore)) warnings++;
  if (passwordsts.nonce && checkPwExpired(passwordsts.nonce)) warnings++;
  if (!user2AuthEnabled.value) warnings++;

  return warnings;
});
const exchangeWarnings = computed(() => {
  let warnings = 0;
  const exchanges = accountManagementStore.getExchanges;

  for (const key in exchanges) {
    const exchange = exchanges[key];

    for (const id in exchange.accounts) {
      const account = exchange.accounts[id];
      if (account?.apiKey?.createdTs && checkPwExpired(account.apiKey.createdTs)) warnings++;
    }
  }

  return warnings;
});
const settingsWarnings = computed(() => exchangeWarnings.value + systemWarnings.value);

// Watchers
watch(currentChosenAsset, (newValue) => {
  chosenAsset.value = newValue;
}, { immediate: true });

watch(chosenAsset, (newValue) => {
  userSettingsStore.updateChosenAsset(newValue);
});

watch(wallets, () => {
  computeWalletsData();
}, { deep: true });

watch(prices, () => {
  computeWalletsData();
}, { deep: true });

// Lifecycle
onMounted(() => {
  setInterval(computeWsActivities, 1000);
});

// Methods
const toggleMenu = () => {
  showMobileMenu.value = !showMobileMenu.value;
};

const logoutUser = async () => {
  if (confirm('Are you sure you want to logout?')) {
    const logout = await userStore.logoutUser();
    if (logout == null) location.reload();
  }
};

const userDecryption = () => {
  if (decryptPws.value === '') return;
  wsStore.send({ category: 'user_decrypt', body: decryptPws.value });
  decryptPws.value = '';
};

const setTradingSession = () => {
  if (tradingSessionPws.value === '' || tradingSessionTtl.value === '') {
    return;
  }

  userStore.setTradingSessionHttp(tradingSessionPws.value, Number(tradingSessionTtl.value) * 60)
    .then((resp) => {
      const tradingSession = JSON.parse(resp) as TradingSessionResp;

      userStore.tradingSessionExpiry = tradingSession.expiresAt;
      tradingSessionPws.value = '';
      tradingSessionTtl.value = '1';
    })
    .catch(() => {
      tradingSessionPws.value = '';
      tradingSessionTtl.value = '1';
    });
};

const clearTradingSession = () => {
  userStore.deleteTradingSessionHttp()
    .then(() => {
      userStore.tradingSessionExpiry = 0;
      tradingSessionPws.value = '';
      tradingSessionTtl.value = '1';
    })
    .catch(() => {
      tradingSessionPws.value = '';
      tradingSessionTtl.value = '1';
    });
};

const computeWalletsData = () => {
  if (new Date().getTime() - walletsDataCacheTime.value < walletsUpdateThrottleTime.value) return;
  if (!Object.keys(wallets.value).length || !Object.keys(prices.value).length) return;
  let totalBalanceTemp = 0;

  for (const accountId in wallets.value) {
    for (const walletId in wallets.value[accountId]) {
      const wallet = wallets.value[accountId][walletId];

      for (const asset in wallet.balances) {
        const balance = wallet.balances[asset];
        const total = parseFloat(balance.total);

        if (total == 0) continue;
        totalBalanceTemp +=
          userSettingsStore.getChosenAssetValue(wallet.exchangeName, asset, total, true);
      }
    }
  }

  userSettingsStore.setChosenAssetTotal(totalBalanceTemp);

  if (chosenAsset.value === 'Percentage') {
    totalBalance.value = 100;
  } else {
    totalBalance.value = totalBalanceTemp;
  }

  walletsDataCacheTime.value = new Date().getTime();
};

const computeWsActivities = () => {
  const monitoringTemp = new MonitoringType(false, []);
  const currentTime = Math.floor(Date.now() / 1000);

  for (const exchangeName in wsActivities.value) {
    for (const wsType in wsActivities.value[exchangeName]) {
      const lastUpdateTime = wsActivities.value[exchangeName][wsType];
      const secondsAgo = currentTime - lastUpdateTime;
      const [hours, minutes, seconds] = new Date(secondsAgo * 1000).toISOString().slice(11, 19).split(':');
      let time = '';

      if (hours !== '00') time += `${parseInt(hours)}h `;
      if (minutes !== '00') time += `${parseInt(minutes)}m `;
      if (seconds !== '00') time += `${parseInt(seconds)}s`;
      if (time === '') time = '0s';

      monitoringTemp.items.push(new MonitoringTypeItem(exchangeName, wsType, time, secondsAgo));

      if (secondsAgo > 20 ) {
        monitoringTemp.error = true;
      }
    }
  }

  monitoring.value = monitoringTemp;
};

const loginResourcesUser = () => {
  void router.replace({ path: '/resources/login' });
};

const logoutResourcesUser = async () => {
  await resourcesStore.logout();
};
</script>

<style lang="scss" scoped>
$mobile-menu-breakpoint: 900px;

.main-header {
  display: flex;
  background: var(--header-bg);
  border-bottom: 1px solid var(--header-border);

  &__right {
    margin-left: auto;
    display: flex;
  }
  .nav {
    border-bottom: 0;
  }
}

.dropdown--main-header {
  .dropdown__combobox {
    border: 2px solid transparent;
    position: relative;
    opacity: .5;
    margin-top: 3px;
    margin-bottom: 2px;
    padding: 8px 12px;


    &--status {
      font-size: 22px;

      &[data-socket-connected="false"],
      &[data-server-connected="false"],
      &[data-socket-authorised="false"],
      &[data-server-ws-activity="true"] {
        opacity: 1;
        i {color: #f00;}
      }
    }
    &--profile {
      font-size: 19px;
      padding-bottom: 12px;
    }

    &:hover {
      opacity: 1;
    }
  }
  &:hover .dropdown__combobox {
    border: 2px solid var(--header-border);
    opacity: 1;

    &:after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
      height: 4px;
      background-color: var(--dropdown-bg--active);
      z-index: 100;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: -20px;
      left: -20px;
      right: -20px;
      top: -20px;
    }
  }

  &:hover .dropdown__menu--columns {
    width: 550px;
    max-width: 50vw;
    max-height: 80vh;
    overflow-y: scroll;
    padding: 5px;
    border: 2px solid var(--header-border);
    top: 44px;
    .dropdown__menu__column {
      flex: 2;
    }

    @media (min-width: $mobile-menu-breakpoint)  {
      display: flex;
    }

    @media (min-width: $mobile-menu-breakpoint)  {
      .dropdown__menu__column--small {
        width: 30%;
        flex: 1;
      }
      .dropdown__menu__column--border {
        border-right: 3px solid var(--header-border);
        padding-right: 5px;
        margin-right: 5px;
      }
    }
  }
  &:hover .dropdown__menu--user-settings {
    width: 250px;
    max-width: 90vw;
    padding: 5px;
    border: 2px solid var(--header-border);
  }
}
.green-text {
  color: #0f0;
}
.red-text {
  color: #f00;
}

// Dropdown CSS inside assets/css/base
.mobile-menu-button {
  border: 2px solid transparent;
  padding: 6px 12px 8px;
  margin: 2px 0 0px;
  font-size: 18px;
  transition: all ease .25s;
  position: relative;
  opacity: .5;
  display: none;

  @media (max-width: $mobile-menu-breakpoint)  {
    display: block;
  }

  &:hover {
    background: var(--dropdown-bg--active);
    border: 2px solid var(--dropdown-border);
    opacity: 1;
  }
}
.nav {
  display: flex;
  background: var(--header-bg);
  border-bottom: 1px solid var(--header-border);
  align-items: center;
  padding-left: 12px;

  @media (max-width: $mobile-menu-breakpoint)  {
    position: absolute;
    top: 46px;
    left: 0;
    bottom: 0;
    width: 300px;
    max-width: 90dvw;
    background-color: var(--header-bg);
    border: 2px solid var(--header-border);
    flex-direction: column;
    z-index: 99999;
    align-items: flex-start;
    overflow-y: scroll;
    padding: 0;
    transform: translateX(-100vw);
    transition: transform 0.2s ease;

    &--mobile-open {
      transform: translateX(0);
    }

    .nav__item {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
    .nav__item__title {
      border-bottom: 2px solid #444;
      width: 100%;
      padding: 10px;
    }
    .nav__item__link {
      border-top: 1px solid #444;
      padding: 8px 20px;
    }

    .nav__item__sub {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0;
      margin: 0 0 14px;
    }
    .nav__item--active {
      background: transparent;
    }
  }


  &__item {
    list-style: none;
    display: flex;
    align-items: center;

    &--active {
      background-color: rgba(#000, .4);
      .nav__item__sub {
        display: flex;
      }
      .nav__item__title {
        color: white;
      }
    }

    &__sub {
      display: none;
      position: relative;
      padding-right: 8px;
      @media (min-width: $mobile-menu-breakpoint)  {
        &:before {
          content: '';
          position: absolute;
          top: 4px;
          bottom: 7px;
          border-left: 1px solid white;
          opacity: .3;
        }
      }
    }

    &__link {
      padding: 13px 4px;
      margin: 0 4px;
      font-size: 14px;
      color: var(--header-link);
      border-bottom: 1px solid transparent;
      transition: color 0.5s ease;

      &:hover {
        color: var(--header-link--hover);
      }
      &--active {
        color: var(--header-link--active);
        border-bottom-color: white;
      }
    }

    &__title {
      padding: 8px 6px;
      margin: 0 6px;
      font-size: 14px;
      color: var(--header-link);
      border-bottom: 1px solid transparent;
      font-weight: bold;
      transition: color 0.5s ease;

      &:hover {
        color: var(--header-link--hover);
      }
    }
  }
}
</style>
