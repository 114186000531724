import { EntityType } from '@/types/exchange';

export enum SIDE { // Generic type for both orders and positions
  UNKNOWN = '',
  BUY = 'buy',
  SELL = 'sell',
}

export class AccountType {
  public id: string;
  public name: string;
  public type: string;
  public apiKey: ApiKeyType | null;

  constructor(id: string, name: string, type: string, apiKey: ApiKeyType | null = null) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.apiKey = apiKey;
  }
}

export class ApiKeyType {
  public key: string;
  public secret: string;
  public name: string;
  public passphrase: string;
  public createdTs: number;

  constructor(key: string, secret: string, name: string, passphrase: string, createdTs: number) {
    this.key = key;
    this.secret = secret;
    this.name = name;
    this.passphrase = passphrase;
    this.createdTs = createdTs;
  }
}

export interface ApiKeyTypeWrapper {
  exchangeName: string;
  accountId: string;
  apiKey: ApiKeyType;
}

export interface AccountManagementState {
  exchanges: Record<string, EntityType>;
  validExchangeNames: Record<string, ExchangeNameTypes>;
}

export interface ExchangeInfoTypeServer {
  exchangeType: string;
}

export interface ExchangeTypeServer {
  name: string;
  category: string;
  custom: boolean;
}

export interface ExchangeServerResp {
  exchange: ExchangeTypeServer;
}

export interface SubExchangeServerResp {
  exchangeName: string;
  subExchange: ExchangeInfoTypeServer;
}

export interface ApiKeyTypeServer {
  createdTs: number;
}

export interface AccountTypeServer {
  id: string;
  name: string;
  type: string;
  apiKey: ApiKeyTypeServer | null;
}

export interface AccountServerResp {
  account: AccountTypeServer;
  exchangeName: string;
}

export interface ExchangeNameTypes {
  All: boolean;
  Spot: boolean;
  Derivatives: boolean;
}

export interface ValidExchangeNamesServerResp {
  data: Record<string, ExchangeNameTypes>;
}

export class AccountApiKeyClientReq {
  key: string;
  secret: string;
  passphrase: string;
  username: string;
  password: string;
  accountId: string;

  constructor(key: string, secret: string, passphrase: string, username: string, password: string, accountId: string) {
    this.key = key;
    this.secret = secret;
    this.passphrase = passphrase;
    this.username = username;
    this.password = password;
    this.accountId = accountId;
  }
}

export class AccountTypeClientReq {
  id: string;
  type: string;
  name: string;
  apiKey: AccountApiKeyClientReq;

  constructor(id: string, type: string, name: string, apiKey: AccountApiKeyClientReq) {
    this.id = id;
    this.type = type;
    this.name = name;
    this.apiKey = apiKey;
  }
}

export class AccountClientReq {
  userToken: string;
  accountId: string;
  exchangeName: string;
  account: AccountTypeClientReq;

  constructor(userToken: string, accountId: string, exchangeName: string, account: AccountTypeClientReq) {
    this.userToken = userToken;
    this.accountId = accountId;
    this.exchangeName = exchangeName;
    this.account = account;
  }
}
