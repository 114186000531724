<template>
  <div class="card">
    <p>IG Trade Analysis</p>

    <section>
      <input
        v-model="fromDate" class="table__header__input-wrap__input" type="text" placeholder="{{ this.getDate() }}"
      />
      <input
        v-model="toDate" class="table__header__input-wrap__input" type="text" placeholder="{{ this.getDate() }}"
      />
      <section>
        <button class="btn-tw" @click="igTradeFetching()">
          Fetch IG Trades
        </button>

        Play speed (ms): <input v-model="speedInMilliseconds" class="btn-tw" type="number" placeholder="1000" />
        <select v-model="selectedMarket" name="selectedMarketFilter" class="chart__header__select">
          <option v-for="market in markets" :key="market[0]" :value="market[0]">
            {{ market[1] }}
          </option>
        </select>
        <button class="btn-tw" @click="playTrades(true)">Play</button>
        <button class="btn-tw" @click="pauseTrades()">Pause</button>
        <button class="btn-tw" @click="resetPlayTrades()">Reset</button>
        <button class="btn-tw" @click="reversePlayTrades()">Previous</button>
        <button class="btn-tw" @click="playTrades(false)">Next</button>
      </section>
    </section>

    <section class="panel priceAction-chart-wrapper">
      <section class="chart-sidebar">
        <section v-if="igActivity.size">
          {{ fetchMsg }}
          <hr /><br />

          <span>
            {{ markets.get(selectedMarket) }} ({{ selectedMarket }})
            - {{ igActivity.get(selectedMarket)?.length }} trade(s)
          </span>
          <section
            v-for="(activity, i) in igActivity.get(selectedMarket)" :key="i" class="panel hand"
            @click="selectActivity(i)"
          >
            <section :class="marketPlayStep - 1 === i ? 'greyBg2' : 'greyBg1'">
              <span :class="activity.status === 'REJECTED' ? 'redBg' : ''" :title="activity.date">
                {{ i + 1 }}) {{ activity.summary }}
              </span>
            </section>
          </section>
        </section>
        <section v-else>
          Fetch trades...
        </section>
      </section>

      <ChartView
        :price-data="priceData" :customisable="false" :disable-t-t-c-c="true" :use-order-position-source="true"
        :chart-bus="chartBus" :orders="Object.fromEntries(marketPlayState.ordersOpen)"
        :positions="Object.fromEntries(marketPlayState.positionsOpen)"
      />
    </section>

    <section>
      Session summary of positions (total PnL = {{ currencyFormatter(totalPnL, 2, '£') }}):
      <section v-for="[market, positions] in closedPositions" :key="market">
        Market: {{ markets.get(market) }}

        <table class="table-tw">
          <thead class="table-thead-tw">
            <tr>
              <th class="table-th-tw">Time</th>
              <th class="table-th-tw">Entry Price</th>
              <th class="table-th-tw">Entry Size</th>
              <th class="table-th-tw">Initial SL</th>
              <th class="table-th-tw">Initial risk</th>
              <th class="table-th-tw">Overall PnL</th>
              <th class="table-th-tw">R:R ratio</th>
            </tr>
          </thead>
          <tbody class="table-tbody-tw">
            <tr
              v-for="[id, position] in positions" :key="id"
              class="table-tr-tw hand" @click="showPosition(market, position as PositionType)"
            >
              <td class="table-td-tw">{{ formatDateTime(position.created * 1000) }}</td>
              <td class="table-td-tw">{{ position.initialPrice }}</td>
              <td class="table-td-tw">{{ position.initialQuantity }}</td>
              <td class="table-td-tw">{{ position.initialStopLoss }}</td>
              <td class="table-td-tw">
                {{
                  position.formatAmount(
                    position.calculatePnL(position.initialPrice, position.initialStopLoss, position.initialQuantity))
                }}
              </td>
              <td class="table-td-tw">{{ position.formatAmount(position.pnl) }}</td>
              <td class="table-td-tw">
                <span v-if="+position.pnl >= 0">
                  1:{{
                    roundNumber(
                      Math.abs(+position.pnl) /
                        Math.abs((+position.initialPrice - +position.initialStopLoss) * +position.initialQuantity),
                      1,
                    ) }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onBeforeMount, defineAsyncComponent } from 'vue';
import { useIgStore } from '@/stores/exchanges/ig';
import { useExchangesSettingsStore } from '@/stores/exchanges/settings';
import { useQueryStringStore } from '@/stores/queryString';
import { useNotificationsStore } from '@/stores/user/notifications';
import { useRoute } from 'vue-router';
import { PriceData, PriceDataServer } from '@/types/pricedata';
import { SIDE } from '@/types/account';
import { IgTradeServerResp } from '@/types/ig';
import {
  ORDER_TYPE, ORDER_STATUS, OrderFlags, ActiveAction, OrderType, ORDER_CATEGORY, AccountMeta,
} from '@/types/orders';
import { PositionType, FillType, POSITION_STATUS } from '@/types/positions';
import { IgMarketState } from '@/types/ig';
import { NOTIFICATION_TYPE, NotificationType } from '@/types/user';
import { currencyFormatter, formatDateTime, roundNumber } from '@/utilities';
import mitt from 'mitt';
import { Event } from '@/types/general';
import { Emitter } from 'mitt';
import { useClientLogsStore } from '@/stores/user/clientLogs';
import { EXCHANGE_NAME, EXCHANGE_TYPE } from '@/types/exchange';

const ChartView = defineAsyncComponent(() =>
  import('@/components/exchanges/symbol/ChartView.vue'),
);

enum PERFORM_STEP {
  BY_ACTIVITY = 0,
  BY_CANDLE = 1,
  BY_BOTH = 2,
}

// Store
const exchangesSettingsStore = useExchangesSettingsStore();
const igStore = useIgStore();
const queryStringStore = useQueryStringStore();
const notificationsStore = useNotificationsStore();
const clientLogsStore = useClientLogsStore();
const route = useRoute();

// Computed
const igActivity = computed(() => igStore.trades);
const igPriceData = computed(() => igStore.priceData);

// Variables
const fromDate = ref<string | null>(null);
const toDate = ref<string | null>(null);
const markets = ref(new Map<string, string>());
const marketPlayStep = ref(0);
const marketPlayTimer = ref(0);
const marketPlayStates = ref(new Map<string, IgMarketState>());
const marketPlayPnLs = ref(new Map<string, number>());
const closedPositions = ref(new Map<string, Map<string, PositionType>>());
const marketPlayState = ref(new IgMarketState());
const totalPnL = ref(0);
const fetchMsg = ref('');
const speedInMilliseconds = ref(1000);
const priceData = ref<PriceData | null>(null);
const igPriceDataIndex = ref(0);
const waitOnPriceDataForActivity = ref(-2); // Blocks until price data comes through
const waitOnPriceDataForPosition = ref<PositionType>(null); // Blocks until price data comes through
const selectedMarket = ref('');
const automatedPlaying = ref(false);
const chartBus = ref<Emitter<Event>>(mitt<Event>());

// Watchers
watch(igActivity, () => {
  if (!igActivity.value.size) {
    fetchMsg.value = 'No trade data found';
    return;
  }

  totalPnL.value = 0;
  marketPlayStep.value = 0;
  marketPlayPnLs.value.clear();
  markets.value.clear();

  let totalTrades = 0;

  for (const [market, activities] of igActivity.value) {
    totalTrades += activities.length;

    if (!marketPlayPnLs.value.has(market)) {
      marketPlayPnLs.value.set(market, 0);
      markets.value.set(market, activities[0].details.marketName);
    }

    selectedMarket.value = market;

    while (performStep(true, false, PERFORM_STEP.BY_ACTIVITY));

    totalPnL.value += marketPlayPnLs.value.get(market);
    closedPositions.value.set(market, marketPlayStates.value.get(market).positionsClosed);

    resetPlayTrades();
  }

  // Deliberately don't set selectedMarket = '' here to autofill the selector with a value.

  if (totalTrades === 1) {
    fetchMsg.value = `${totalTrades} trade found across 1 market`;
  } else {
    fetchMsg.value = `${totalTrades} trades found across ${igActivity.value.size} market`;

    if (igActivity.value.size > 1) {
      fetchMsg.value += 's';
    }
  }
});

watch(igPriceData, () => {
  if (waitOnPriceDataForActivity.value > -2) {
    setPriceData();
    startPlaying(waitOnPriceDataForActivity.value);
    waitOnPriceDataForActivity.value = -2;
  } else if (waitOnPriceDataForPosition.value !== null) {
    showPositionInternal(waitOnPriceDataForPosition.value);

    waitOnPriceDataForPosition.value = null;
  }
});

watch(fromDate, () => {
  if (fromDate.value !== route.query.fromDate as string) {
    queryStringStore.update({ 'fromDate': fromDate.value || null });
  }
});

watch(toDate, () => {
  if (toDate.value !== route.query.toDate as string) {
    queryStringStore.update({ 'toDate': toDate.value || null });
  }
});

// Vue Lifecycle Functions
onBeforeMount(() => {
  fromDate.value = route.query.fromDate as string || getDate();
  toDate.value = route.query.toDate as string || getDate();
});

// Methods
const igTradeFetching = () => {
  waitOnPriceDataForActivity.value = -2;
  igStore.resetPriceData();
  resetPlayTrades();

  igStore.fetchIgAccountActivity(
    new AccountMeta('', EXCHANGE_NAME.IG, EXCHANGE_TYPE.DERIVATIVES),
    new Date(fromDate.value).getTime(),
    new Date(toDate.value).getTime() + 1000 * 60 * 60 * 24,
  ).then((jsonResp) => {
    igStore.setTrades(JSON.parse(jsonResp) as IgTradeServerResp);
  }).catch(() => {
    // Nothing more to do
  });
};

const getPriceData = (market: string) => {
  igStore.fetchIgPriceData(
    new AccountMeta('', EXCHANGE_NAME.IG, EXCHANGE_TYPE.DERIVATIVES),
    new Date(fromDate.value).getTime(),
    new Date(toDate.value).getTime() + 1000 * 60 * 60 * 24,
    market,
  ).then((jsonResp) => {
    igStore.setPriceData(JSON.parse(jsonResp) as PriceDataServer);
  }).catch(() => {
    // Nothing more to do
  });
};

const showPosition = (market: string, position: PositionType) => {
  if (!igPriceData.value) {
    getPriceData(market);
    waitOnPriceDataForPosition.value = position;
    return;
  }

  showPositionInternal(position);
};

const showPositionInternal = (position: PositionType) => {
  resetPlayTrades();
  marketPlayState.value.positionsOpen.set(position.id, position);
  setPriceData();
  priceData.value.candleChanges = igPriceData.value.candles;
};

const playTrades = (automated: boolean) => {
  automatedPlaying.value = automated;

  if (marketPlayTimer.value !== 0) {
    return;
  }

  if (marketPlayStep.value === 0) {
    if (!igPriceData.value) {
      getPriceData(selectedMarket.value);
      waitOnPriceDataForActivity.value = -1;
      return;
    }

    setPriceData();
  }

  startPlaying();
};

const reversePlayTrades = (stepBy: PERFORM_STEP = PERFORM_STEP.BY_BOTH) => {
  if (marketPlayStep.value === 0 || !igPriceData.value) {
    return;
  }

  automatedPlaying.value = false;

  let jumpN = 0;

  switch (stepBy) {
  case PERFORM_STEP.BY_ACTIVITY:
    jumpN = marketPlayStep.value - 2;
    break;
  case PERFORM_STEP.BY_CANDLE: {
    const activities = igActivity.value.get(selectedMarket.value);
    const endTime = priceData.value.candles[igPriceDataIndex.value - 1].startTime;

    for (let i = marketPlayStep.value - 2; i >= 0; --i) {
      const activityTime = new Date(activities[i].date).getTime() / 1000;

      if (activityTime <= endTime) {
        jumpN = i;
        break;
      }
    }
    break;
  }
  case PERFORM_STEP.BY_BOTH: {
    const activities = igActivity.value.get(selectedMarket.value);
    const activityTime = new Date(activities[marketPlayStep.value - 1].date).getTime() / 1000;
    const endTime = Math.min(activityTime, priceData.value.candles[igPriceDataIndex.value - 1].startTime);

    for (let i = marketPlayStep.value - 2; i >= 0; --i) {
      const activityTime = new Date(activities[i].date).getTime() / 1000;

      if (activityTime <= endTime) {
        jumpN = i;
        break;
      }
    }
    break;
  }
  }

  selectActivity(jumpN);
};

const pauseTrades = () => {
  clearInterval(marketPlayTimer.value);
  marketPlayTimer.value = 0;
  automatedPlaying.value = false;
};

const resetPlayTrades = (resetPriceData = true) => {
  pauseTrades();
  marketPlayStep.value = 0;
  marketPlayState.value = new IgMarketState();
  marketPlayStates.value.set(selectedMarket.value, marketPlayState.value);
  automatedPlaying.value = false;

  if (resetPriceData) {
    igPriceDataIndex.value = 0;
    setPriceData();
  }
};

const selectActivity = (jumpN: number) => {
  if (jumpN === marketPlayStep.value - 1) {
    return;
  }

  if (!igPriceData.value) {
    getPriceData(selectedMarket.value);
    waitOnPriceDataForActivity.value = jumpN;
    return;
  }

  const reversing = (jumpN < marketPlayStep.value - 1);
  const activities = igActivity.value.get(selectedMarket.value);
  const activityTime = new Date(activities[jumpN].date).getTime() / 1000;
  let candlesToPop = 0;
  let newIgPriceDataIndex = 0;

  if (reversing) {
    for (; newIgPriceDataIndex < priceData.value.candles.length; ++newIgPriceDataIndex) {
      if (priceData.value.candles[newIgPriceDataIndex].startTime > activityTime) {
        break;
      }
    }

    candlesToPop = igPriceDataIndex.value - newIgPriceDataIndex;
    resetPlayTrades(false);
  }

  while (marketPlayStep.value <= jumpN) {
    performStep(false, false, PERFORM_STEP.BY_ACTIVITY);
  }

  if (reversing) {
    igPriceDataIndex.value = newIgPriceDataIndex;

    for (; candlesToPop > 0; --candlesToPop) {
      chartBus.value.emit('action', 'popCandle');
    }
  }
};

const startPlaying = (jumpN = -1) => {
  performStep(false, false, jumpN === -1 ? PERFORM_STEP.BY_BOTH : PERFORM_STEP.BY_ACTIVITY);

  if (automatedPlaying.value) {
    marketPlayTimer.value = window.setInterval(
      () => performStep(false, false, PERFORM_STEP.BY_BOTH),
      speedInMilliseconds.value,
    );
  }
};

const setPriceData = () => {
  chartBus.value.emit('action', 'resetChart');

  let tickerName = '';
  let timeframe = 0;

  if (igPriceData.value) {
    tickerName = igPriceData.value.ticker;
    timeframe = igPriceData.value.timeframe;
  }

  priceData.value = new PriceData(tickerName, timeframe, 0, 0, []);
};

const performStep = (buildState: boolean, notifications: boolean, stepBy: PERFORM_STEP): boolean => {
  if (buildState && stepBy !== PERFORM_STEP.BY_ACTIVITY) {
    clientLogsStore.errorLog(
      '[IG] When building state, steps must be performed by activity (price data may not yet be available)',
    );
    return false;
  }

  const activities = igActivity.value.get(selectedMarket.value);

  if (marketPlayStep.value === activities.length) {
    if (!buildState) {
      // Finish by putting the rest of the candles on the chart
      for (; igPriceDataIndex.value < igPriceData.value.candles.length; ++igPriceDataIndex.value) {
        priceData.value.candleChanges.push(igPriceData.value.candles[igPriceDataIndex.value]);
      }

      pauseTrades();
    }

    return false;
  }

  // Debug data
  // const igPriceDataIndexOld = this.igPriceDataIndex;
  // const candleChangesLength = this.priceData.candleChanges.length;
  // const stepOld = this.marketPlayStep;

  let activityTime: number;
  let stepEndTime: number;
  let marketPlayStateNew = new IgMarketState();

  switch (stepBy) {
  case PERFORM_STEP.BY_ACTIVITY:
    // Move forward 1 step in activities, including any relevant candles
    activityTime = new Date(activities[marketPlayStep.value].date).getTime() / 1000;
    stepEndTime = activityTime;

    if (!buildState) {
      for (; igPriceDataIndex.value < igPriceData.value.candles.length; ++igPriceDataIndex.value) {
        if (igPriceData.value.candles[igPriceDataIndex.value].startTime > activityTime) {
          break;
        }

        priceData.value.candleChanges.push(igPriceData.value.candles[igPriceDataIndex.value]);
      }
    }
    break;
  case PERFORM_STEP.BY_CANDLE:
    // Move forward 1 step in candles, including any relevant activities
    priceData.value.candleChanges.push(igPriceData.value.candles[igPriceDataIndex.value]);
    stepEndTime = igPriceData.value.candles[igPriceDataIndex.value].endTime;
    ++igPriceDataIndex.value;
    break;
  case PERFORM_STEP.BY_BOTH:
    // Move forward at least 1 step in activities and at least one step in candles
    activityTime = new Date(activities[marketPlayStep.value].date).getTime() / 1000;
    stepEndTime = Math.max(activityTime, igPriceData.value.candles[igPriceDataIndex.value].endTime);
    priceData.value.candleChanges.push(igPriceData.value.candles[igPriceDataIndex.value]);

    for (
      ++igPriceDataIndex.value; igPriceDataIndex.value < igPriceData.value.candles.length; ++igPriceDataIndex.value
    ) {
      if (igPriceData.value.candles[igPriceDataIndex.value].startTime > activityTime) {
        break;
      }

      priceData.value.candleChanges.push(igPriceData.value.candles[igPriceDataIndex.value]);
      stepEndTime = igPriceData.value.candles[igPriceDataIndex.value].endTime;
    }

    break;
  }

  if (marketPlayStep.value === 0) {
    marketPlayStates.value.set(selectedMarket.value, marketPlayStateNew);
  } else {
    marketPlayStateNew = marketPlayStates.value.get(selectedMarket.value) as IgMarketState;
  }

  marketPlayState.value = marketPlayStateNew;

  // Perform step by applying it to the marketPlayState

  for (; marketPlayStep.value < activities.length; ++marketPlayStep.value) {
    const activity = activities[marketPlayStep.value];
    const activityTime = new Date(activity.date).getTime() / 1000;

    if (activityTime > stepEndTime) {
      break;
    }

    activity.summary = JSON.stringify(activity);

    if (activity.status === 'REJECTED') {
      let msg = '';

      if (activity.type === 'WORKING_ORDER') {
        msg = `Rejected order: ${activity.details.direction} ${activity.details.size} @ ${activity.details.level}`;
      } else {
        clientLogsStore.errorLog(
          `[IG] Unknown activity type: '${JSON.stringify(activity)}'`,
        );
        msg = `Rejected: ${JSON.stringify(activity)}`;
      }

      if (notifications) {
        notificationsStore.addNotification(new NotificationType(NOTIFICATION_TYPE.ERROR, msg));
      }

      activity.summary = msg;

      continue;
    }

    if (activity.status !== 'ACCEPTED') {
      clientLogsStore.errorLog(
        `[IG] Unknown activity status: ${JSON.stringify(activity)}`,
      );
      continue;
    }

    if (activity.details.actions.length === 0) {
      clientLogsStore.errorLog(
        `[IG] Unknown activity: ${JSON.stringify(activity)}`,
      );
      continue;
    }

    const action1 = activity.details.actions[0];

    if (activity.details.actions.length > 1) {
      if (action1.actionType !== 'POSITION_OPENED') {
        clientLogsStore.errorLog(
          `[IG] Unknown activity: ${JSON.stringify(activity)}`,
        );
        continue;
      }
    }

    switch (action1.actionType) {
    case 'STOP_ORDER_OPENED':
    case 'LIMIT_ORDER_OPENED': {
      if (marketPlayStateNew.ordersOpen.has(activity.dealId)) {
        clientLogsStore.errorLog(
          `[IG] Unexpected order already open: ${JSON.stringify(activity)}`,
        );
        break;
      }

      const type = (action1.actionType === 'STOP_ORDER_OPENED' ? ORDER_TYPE.MARKET : ORDER_TYPE.LIMIT);
      const order = new OrderType(activity.dealId);
      const exchangeTypeSettings = exchangesSettingsStore.getExchangeInfoSettings(
        EXCHANGE_NAME.IG,
        EXCHANGE_TYPE.DERIVATIVES,
      );

      order.exchangeId = activity.dealId;
      order.symbol = activity.details.marketName;
      order.created = activityTime;
      order.updated = activityTime;
      order.quantity = activity.details.size;
      order.displayQuantity = activity.details.size;
      order.marginAsset = activity.details.currency;
      order.price = activity.details.level;
      order.stopLossPrice = activity.details.stopLevel;
      order.profitTargetPrice = activity.details.limitLevel;
      order.leverage = '1';
      order.flags = new OrderFlags(-1, true, false, false);
      order.status = ORDER_STATUS.UNFILLED;
      order.side = activity.details.direction === 'BUY' ? SIDE.BUY : SIDE.SELL;
      order.type = type;
      order.calculatePnLInternal = exchangeTypeSettings.pnlCalc;
      order.exchangeName = EXCHANGE_NAME.IG;
      order.exchangeType = EXCHANGE_TYPE.DERIVATIVES;
      order.consistentSymbol = '';
      order.chosenAssetProfit = '';
      order.computed = false;
      order.currency = exchangeTypeSettings.orderCurrency;
      order.category = ORDER_CATEGORY.TRADING;
      order.activeAction = new ActiveAction();

      marketPlayStateNew.ordersOpen.set(activity.dealId, order);

      if (notifications) {
        notificationsStore.addNotification(new NotificationType(
          NOTIFICATION_TYPE.SUCCESS, `Order (${type}) opened: ${order.ToString()}`),
        );
      }

      activity.summary = `Order (${type}) opened: ${order.ToString()}`;
      break;
    }
    case 'STOP_ORDER_AMENDED':
    case 'LIMIT_ORDER_AMENDED': {
      if (!marketPlayStateNew.ordersOpen.has(action1.affectedDealId)) {
        clientLogsStore.errorLog(
          `[IG] Unknown limit order: ${JSON.stringify(activity)}`,
        );
        break;
      }

      const order = marketPlayStateNew.ordersOpen.get(action1.affectedDealId);

      order.price = activity.details.level;
      order.stopLossPrice = activity.details.stopLevel;
      order.profitTargetPrice = activity.details.limitLevel;

      if (notifications) {
        notificationsStore.addNotification(
          new NotificationType(NOTIFICATION_TYPE.SUCCESS, `Order amended: ${order.ToString()}`),
        );
      }

      activity.summary = `Order amended: ${order.ToString()}`;
      break;
    }
    case 'STOP_LIMIT_AMENDED': {
      if (marketPlayStateNew.ordersOpen.has(activity.dealId)) {
        const order = marketPlayStateNew.ordersOpen.get(activity.dealId);

        order.stopLossPrice = activity.details.stopLevel;
        order.profitTargetPrice = activity.details.limitLevel;

        if (notifications) {
          notificationsStore.addNotification(
            new NotificationType(NOTIFICATION_TYPE.SUCCESS, `Order amended (stop, limit): ${order.ToString()}`),
          );
        }

        activity.summary = `Order amended (stop, limit): ${order.ToString()}`;
      } else {
        if (marketPlayStateNew.positionsOpen.has(action1.affectedDealId)) {
          const position = marketPlayStateNew.positionsOpen.get(action1.affectedDealId);

          position.stopLossPrice = activity.details.stopLevel;
          position.profitTargetPrice = activity.details.limitLevel;

          if (notifications) {
            notificationsStore.addNotification(
              new NotificationType(NOTIFICATION_TYPE.SUCCESS, `Position amended (stop, limit): ${position.ToString()}`),
            );
          }

          activity.summary = `Position amended (stop, limit): ${position.ToString()}`;
        } else {
          clientLogsStore.errorLog(
            `[IG] Amendment to an unknown position/order: ${JSON.stringify(activity)}`,
          );
        }
      }

      break;
    }
    case 'STOP_ORDER_DELETED':
    case 'LIMIT_ORDER_DELETED':
    case 'WORKING_ORDER_DELETED': {
      if (!marketPlayStateNew.ordersOpen.has(action1.affectedDealId)) {
        clientLogsStore.errorLog(
          `[IG] Order not found: ${JSON.stringify(activity)}`,
        );
        break;
      }

      const order = marketPlayStateNew.ordersOpen.get(action1.affectedDealId);

      order.status = ORDER_STATUS.CANCELLED;
      marketPlayStateNew.ordersClosed.set(action1.affectedDealId, order);
      marketPlayStateNew.ordersOpen.delete(action1.affectedDealId);

      if (notifications) {
        notificationsStore.addNotification(
          new NotificationType(NOTIFICATION_TYPE.SUCCESS, `Order removed: ${order.ToString()}`),
        );
      }

      activity.summary = `Order removed: ${order.ToString()}`;
      break;
    }
    case 'POSITION_OPENED': {
      let reason = '';

      if (activity.details.actions.length === 1) {
        clientLogsStore.errorLog(
          `[IG] Unknown activity format (expected 2 actions): ${JSON.stringify(activity)}`,
        );
      } else {
        const action2 = activity.details.actions[1];

        switch (action2.actionType) {
        case 'LIMIT_ORDER_FILLED':
          reason = 'from limit order';
          break;
        case 'STOP_ORDER_FILLED':
          reason = 'from stop market order';
          break;
        default:
          clientLogsStore.errorLog(
            `[IG] Unknown activity action type: ${JSON.stringify(activity)}`,
          );
          break;
        }
      }

      if (notifications) {
        notificationsStore.addNotification(
          new NotificationType(NOTIFICATION_TYPE.SUCCESS, `New position opened ${reason}`),
        );
      }

      if (!marketPlayStateNew.ordersOpen.has(action1.affectedDealId)) {
        clientLogsStore.errorLog(
          `[IG] Order not found: ${JSON.stringify(activity)}`,
        );
        break;
      }

      const order = marketPlayStateNew.ordersOpen.get(action1.affectedDealId);
      const fill = new FillType();

      fill.quantity = activity.details.size;
      fill.created = activityTime;
      fill.price = activity.details.level;
      fill.type = order.type as ORDER_TYPE;
      fill.fee = '0';
      fill.pnl = '0';
      fill.side = order.side;

      const position = new PositionType(action1.affectedDealId);
      const exchangeTypeSettings = exchangesSettingsStore.getExchangeInfoSettings(
        EXCHANGE_NAME.IG,
        EXCHANGE_TYPE.DERIVATIVES,
      );

      position.symbol = activity.epic;
      position.created = activityTime;
      position.updated = activityTime;
      position.setPrice(activity.details.level);
      position.setStopLoss(activity.details.stopLevel);
      position.setProfitTarget(activity.details.limitLevel);
      position.setQuantity(activity.details.size);
      position.margin = '';
      position.marginAsset = activity.details.currency;
      position.side = activity.details.direction === 'BUY' ? SIDE.BUY : SIDE.SELL;
      position.status = POSITION_STATUS.OPEN;
      position.addFill(fill);
      position.leverage = '1';
      position.liquidation = '';
      position.accountId = '';
      position.calculatePnLInternal = exchangeTypeSettings.pnlCalc;
      position.currency = exchangeTypeSettings.positionCurrency;

      order.status = ORDER_STATUS.FILLED;

      marketPlayStateNew.ordersClosed.set(action1.affectedDealId, order);
      marketPlayStateNew.ordersOpen.delete(action1.affectedDealId);
      marketPlayStateNew.positionsOpen.set(action1.affectedDealId, position);

      activity.summary = `Position opened ${reason}: ${position.ToString()}`;
      break;
    }
    case 'POSITION_PARTIALLY_CLOSED': {
      if (!marketPlayStateNew.positionsOpen.has(action1.affectedDealId)) {
        clientLogsStore.errorLog(
          `[IG] Unknown position closed: ${JSON.stringify(activity)}`,
        );
        break;
      }

      const position = marketPlayStateNew.positionsOpen.get(action1.affectedDealId);
      let currentPnL: number;

      if (position.side === SIDE.BUY) {
        currentPnL = ((+activity.details.level - +position.price) * +activity.details.size);
      } else {
        currentPnL = ((+position.price - +activity.details.level) * +activity.details.size);
      }

      const order = marketPlayStateNew.ordersOpen.get(activity.dealId);
      const fill = new FillType();

      fill.quantity = activity.details.size;
      fill.created = activityTime;
      fill.price = activity.details.level;
      fill.type = order.type as ORDER_TYPE;
      fill.fee = '0';
      fill.pnl = String(currentPnL);
      fill.side = order.side;

      position.addFill(fill);

      order.status = ORDER_STATUS.FILLED;
      position.quantity = String(+position.quantity - +activity.details.size);
      position.pnl = String(+position.pnl + currentPnL);

      marketPlayStateNew.ordersClosed.set(activity.dealId, order);
      marketPlayStateNew.ordersOpen.delete(activity.dealId);
      marketPlayPnLs.value.set(selectedMarket.value, marketPlayPnLs.value.get(selectedMarket.value) + currentPnL);
      activity.summary = `Position partially closed: ${position.ToString()}`;
      break;
    }
    case 'POSITION_CLOSED': {
      if (!marketPlayStateNew.positionsOpen.has(action1.affectedDealId)) {
        clientLogsStore.errorLog(
          `[IG] Unknown position closed: ${JSON.stringify(activity)}`,
        );
        break;
      }

      const position = marketPlayStateNew.positionsOpen.get(action1.affectedDealId);

      if (+position.quantity !== +activity.details.size) {
        clientLogsStore.errorLog(
          '[IG] Closing a position expects the activity size to equal position size ' +
          `(${position.quantity} !== ${activity.details.size}`,
        );
      }

      let currentPnL: number;

      if (position.side === SIDE.BUY) {
        currentPnL = ((+activity.details.level - +position.price) * +position.quantity);
      } else {
        currentPnL = ((+position.price - +activity.details.level) * +position.quantity);
      }

      const fill = new FillType();

      fill.quantity = position.quantity;
      fill.created = activityTime;
      fill.price = activity.details.level;
      fill.type = ORDER_TYPE.LIMIT; // TODO
      fill.fee = '0';
      fill.pnl = String(currentPnL);
      fill.side = position.side === SIDE.BUY ? SIDE.SELL : SIDE.BUY;

      position.addFill(fill);

      position.status = POSITION_STATUS.CLOSED;
      position.pnl = String(+position.pnl + currentPnL);
      marketPlayPnLs.value.set(selectedMarket.value, marketPlayPnLs.value.get(selectedMarket.value) + currentPnL);
      marketPlayStateNew.positionsClosed.set(action1.affectedDealId, position);
      marketPlayStateNew.positionsOpen.delete(action1.affectedDealId);

      if (marketPlayStateNew.ordersOpen.has(activity.dealId)) {
        marketPlayStateNew.ordersClosed.set(activity.dealId, marketPlayStateNew.ordersOpen.get(activity.dealId));
        marketPlayStateNew.ordersOpen.delete(activity.dealId);
      } else {
        // We could recreate the limit PT order here for the position.
      }

      if (notifications) {
        const msg = `Position closed: ${position.ToString()}`;
        notificationsStore.addNotification(new NotificationType(NOTIFICATION_TYPE.SUCCESS, msg));
      }

      activity.summary = `Position closed: ${position.ToString()}`;
      break;
    }
    default:
      clientLogsStore.errorLog(
        `[IG] Unknown action type: ${JSON.stringify(activity)}`,
      );
      break;
    }

    if (stepBy === PERFORM_STEP.BY_ACTIVITY) {
      // For the edge case of multiple activities in the same second
      ++marketPlayStep.value;
      break;
    }
  }

  // Debug data
  // if (!buildState) {
  //   clientLogsStore.errorLog(
  //     `[IG] Step: igPriceDataIndex: ${igPriceDataIndexOld} => ${this.igPriceDataIndex}, ` +
  //     `candleChangesLength: ${candleChangesLength} => ${this.priceData.candleChanges.length}, ` +
  //     `marketPlayStep: ${stepOld} => ${this.marketPlayStep}`,
  //   );
  // }

  if (marketPlayStep.value === activities.length) {
    if (!buildState) {
      pauseTrades();
    }

    return false;
  }

  return true;
};

const getDate = () => {
  const date = new Date();
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const yyyy = date.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};
</script>

<style></style>
