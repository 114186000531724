import { generateClientId } from '@/utilities';

export enum LOG_SEVERITY {
  INFO = 'INFO',
  NOTICE = 'NOTICE',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  FATAL = 'FATAL',
}

export class LogsWrapper {
  statusId: string;
  categoryWs: string;
  fileName: string;
  maxLines = 10000;

  constructor(categoryWs: string) {
    this.statusId = generateClientId();
    this.categoryWs = categoryWs;
  }
}

export interface ServerLogsState {
  fileNames: string[]
  file: string[]
}
