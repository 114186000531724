export interface AbstractQuantity {
  quantity: string;
  chosenAssetQuantity: string;
}

export class OrderbookRowChange {
  buyQuantity: string;
  sellQuantity: string;
  side: string;
  price: string;
  quantity: string;
  chosenAssetQuantity: string;

  constructor(
    buyQuantity: string, sellQuantity: string, side: string, price: string, quantity: string,
    chosenAssetQuantity: string,
  ) {
    this.buyQuantity = buyQuantity;
    this.sellQuantity = sellQuantity;
    this.side = side;
    this.price = price;
    this.quantity = quantity;
    this.chosenAssetQuantity = chosenAssetQuantity;
  }
}

export class OrderbookType {
  public exchangeName: string;
  public exchangeType: string;
  public symbol: string;
  public buys: Record<string, AbstractQuantity>; // price => AbstractQuantity
  public sells: Record<string, AbstractQuantity>; // price => AbstractQuantity
  public incompleteBuys: boolean;
  public incompleteSells: boolean;
  public forOrderflow: boolean;
  public recentChanges: Map<string, OrderbookRowChange>; // for updating lightweight-charts

  constructor(
    exchangeName: string,
    exchangeType: string,
    symbol: string,
    forOrderflow: boolean,
    buys: Record<string, AbstractQuantity>,
    sells: Record<string, AbstractQuantity>,
    incompleteBuys: boolean,
    incompleteSells: boolean,
  ) {
    this.exchangeName = exchangeName;
    this.exchangeType = exchangeType;
    this.symbol = symbol;
    this.forOrderflow = forOrderflow;
    this.buys = buys;
    this.sells = sells;
    this.incompleteBuys = incompleteBuys;
    this.incompleteSells = incompleteSells;
    this.recentChanges = new Map();
  }
}

export type OrderbookLiquidityKeys = keyof OrderbookLiquidity;

export class OrderbookLiquidity {
  public exchangeName: string;
  public exchangeType: string;
  public symbol: string;
  public consistentSymbol: string;
  public buysTotal: string;
  public sellsTotal: string;

  constructor(
    exchangeName: string,
    exchangeType: string,
    symbol: string,
    consistentSymbol: string,
    buysTotal: string,
    sellsTotal: string,
  ) {
    this.exchangeName = exchangeName;
    this.exchangeType = exchangeType;
    this.symbol = symbol;
    this.consistentSymbol = consistentSymbol;
    this.buysTotal = buysTotal;
    this.sellsTotal = sellsTotal;
  }
}

export interface OrdersbooksState {
  orderbooks: Record<string, Record<string, Record<string, OrderbookType>>>;
  orderbooksLiquidity: Record<string, OrderbookLiquidity>;
}

export interface OrderbookServerResp {
  exchangeName: string;
  exchangeType: string;
  symbol: string;
  orderbook: OrderbookTypeServer;
}

export interface OrderbookChangesServerResp {
  exchangeName: string;
  exchangeType: string;
  symbol: string;
  quantity: string;
  updates: OrderbookRowChangeServer[];
}

export interface OrderbookTypeServer {
  buys: Record<string, string>;
  sells: Record<string, string>;
  incompleteBuys: boolean;
  incompleteSells: boolean;
}

export interface OrderbookRowChangeServer {
  category: string;
  side: string;
  price: string;
  quantity: string;
}

export interface OrderbookLiquidityServerResp {
  category: string;
  type: string;
  exchangeName: string;
  exchangeType: string;
  symbol: string;
  consistentSymbol: string;
  buysTotal: string;
  sellsTotal: string;
}
