export class SelectedInstrument {
  exchangeName: string;
  exchangeType: string;
  symbol: string;
  consistentSymbol: string;

  constructor(exchangeName: string, exchangeType: string, symbol: string, consistentSymbol: string) {
    this.exchangeName = exchangeName;
    this.exchangeType = exchangeType;
    this.symbol = symbol;
    this.consistentSymbol = consistentSymbol;
  }
}

export type InstrumentViewKeys = keyof InstrumentView;

export class InstrumentView {
  id: string;
  exchangeName: string;
  exchangeType: string;
  symbol: string;
  consistentSymbol: string;
  marginAsset: string;
  released: number;
  lastPrice: string;
  markPrice: string;
  quoteVolume: string;
  instrument: InstrumentType;

  constructor(
    id: string, exchangeName: string, exchangeType: string, symbol: string, consistentSymbol: string,
    marginAsset: string, released: number, lastPrice: string, markPrice: string, quoteVolume: string,
    instrument: InstrumentType,
  ) {
    this.id = id;
    this.exchangeName = exchangeName;
    this.exchangeType = exchangeType;
    this.symbol = symbol;
    this.consistentSymbol = consistentSymbol;
    this.marginAsset = marginAsset;
    this.released = released;
    this.lastPrice = lastPrice;
    this.markPrice = markPrice;
    this.quoteVolume = quoteVolume;
    this.instrument = instrument;
  }
}

export class InstrumentType {
  public symbol: string; // What the actual name is (for this exchange)
  public consistentSymbol: string; // What the consistent name is (across exchanges)
  public baseAsset: string;
  public quoteAsset: string;
  public minQuantity: string;
  public maxQuantity: string;
  public tickSize: string;
  public tickPrecision: number;
  public quantitySize: string;
  public quantityPrecision: number;
  public released: number;
  public expiry: number;
  public open: boolean;
  public leverage: string;
  // Why is this a number and leverage a string?
  public maxLeverage: number;
  public crossMargin: boolean;
  public takerFee: string; // Bitmex only for margin calculation
  public isInverse: boolean; // Bitmex only for margin calculation
  public isQuanto: boolean; // Bitmex only for margin calculation
  public valueMultiplier: string; // Bitmex only for margin calculation
  public lotMultiplier: string; // Bitmex only for order creation
  public marginAsset: string;
  public quantityAsset: string;
  public showOverview = false;

  constructor(
    symbol: string, consistentSymbol: string, baseAsset: string, quoteAsset: string, minQuantity: string,
    maxQuantity: string, tickSize: string, tickPrecision: number, quantitySize: string, quantityPrecision: number,
    released: number, expiry: number, open: boolean, leverage: string, maxLeverage: number, crossMargin: boolean,
    takerFee: string, isInverse: boolean, isQuanto: boolean, valueMultiplier: string, lotMultiplier: string,
    marginAsset: string, quantityAsset: string,
  ) {
    this.symbol = symbol;
    this.consistentSymbol = consistentSymbol;
    this.baseAsset = baseAsset;
    this.quoteAsset = quoteAsset;
    this.minQuantity = minQuantity;
    this.maxQuantity = maxQuantity;
    this.tickSize = tickSize;
    this.tickPrecision = tickPrecision;
    this.quantitySize = quantitySize;
    this.quantityPrecision = quantityPrecision;
    this.released = released;
    this.expiry = expiry;
    this.open = open;
    this.leverage = leverage;
    this.maxLeverage = maxLeverage;
    this.crossMargin = crossMargin;
    this.takerFee = takerFee;
    this.isInverse = isInverse;
    this.isQuanto = isQuanto;
    this.valueMultiplier = valueMultiplier;
    this.lotMultiplier = lotMultiplier;
    this.marginAsset = marginAsset;
    this.quantityAsset = quantityAsset;
  }
}

export class PriceInfo {
  public tickPrecision: number;
  public tickSize: number;

  constructor(tickPrecision: number, tickSize: number) {
    this.tickPrecision = tickPrecision;
    this.tickSize = tickSize;
  }
}

export interface InstrumentTypeServer {
  symbol: string;
  consistentSymbol: string;
  baseAsset: string;
  quoteAsset: string;
  type: string;
  minQuantity: string;
  maxQuantity: string;
  minPrice: string;
  maxPrice: string;
  tickSize: string;
  tickPrecision: number;
  quantitySize: string;
  quantityPrecision: number;
  leverage: string;
  crossMargin: boolean;
  expiry: number;
  open: boolean;
  released: number;
  makerFee: string;
  takerFee: string;
  maxLeverage: number;
  marginAsset: string;
  quantityAsset: string;
  lotMultiplier: string;
  isInverse: boolean;
  isQuanto: boolean;
  valueMultiplier: string;
}

export interface InstrumentServerResp {
  exchangeName: string;
  exchangeType: string;
  symbol: string;
  instrument: InstrumentTypeServer;
}

export interface InstrumentsServerResp {
  exchangeName: string;
  exchangeType: string;
  symbol: string;
  instruments: Record<string, InstrumentTypeServer>;
}

export interface InstrumentState {
  instruments: Record<string, Record<string, Record<string, InstrumentType>>>;
  nameMappings: Record<string, Record<string, Record<string, string[]>>>;
  assetMapping: Record<string, Record<string, Record<string, Record<string, boolean>>>>;
  cachedInstruments: Record<string, InstrumentType>;
  instrument: InstrumentType | null;
}
