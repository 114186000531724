import { defineStore } from 'pinia';
import Config from '@/config';
import { SIDE } from '@/types/account';
import {
  AccountMeta, OrderType, ORDER_STATUS, OrderTypeServer, OrdersServerResp,
  OrderServerResp, OrdersState,
  ActiveAction,
} from '@/types/orders';
import { useUserStore } from '@/stores/user/user';
import { useExchangesSettingsStore } from '@/stores/exchanges/settings';
import { useOrderStrategiesStore } from '@/stores/exchanges/order_strategies';
import { createRequestData, performHttpRequest } from '@/utilities';
import { useClientLogsStore } from '@/stores/user/clientLogs';


export function ConvertOrderTypeServer(order: OrderTypeServer, exchangeName: string, accountId: string): OrderType {
  // Order type validation
  if (!(Object.values(ORDER_STATUS).includes(order.status as ORDER_STATUS))) {
    useClientLogsStore().errorLog(
      `[${exchangeName}][${order.exchangeType}][${order.symbol}] Order status '${order.status}' invalid`);
    return null;
  }

  if (!(Object.values(SIDE).includes(order.side as SIDE))) {
    useClientLogsStore().errorLog(
      `[${exchangeName}][${order.exchangeType}][${order.symbol}] Order side '${order.side}' invalid`);
    return null;
  }

  const newOrder = new OrderType(order.id);
  const exchangeTypeSettings = useExchangesSettingsStore().getExchangeInfoSettings(
    exchangeName,
    order.exchangeType,
  );

  newOrder.exchangeId = order.exchangeId;
  newOrder.clientId = order.clientId;
  newOrder.symbol = order.symbol;
  newOrder.created = order.created;
  newOrder.updated = order.updated;
  newOrder.quantity = order.quantity;
  newOrder.filledQuantity = order.filledQuantity;
  newOrder.displayQuantity = order.displayQuantity;
  newOrder.margin = order.margin;
  newOrder.marginAsset = order.marginAsset;
  newOrder.price = order.price;
  newOrder.stopLossPrice = order.stopLossPrice;
  newOrder.profitTargetPrice = order.profitTargetPrice;
  newOrder.leverage = order.leverage;
  newOrder.flags = order.flags;
  newOrder.status = order.status as ORDER_STATUS;
  newOrder.side = order.side as SIDE;
  newOrder.type = order.type;
  newOrder.accountId = accountId;
  newOrder.calculatePnLInternal = exchangeTypeSettings.pnlCalc;
  newOrder.currency = exchangeTypeSettings.orderCurrency;
  newOrder.category = order.category;
  newOrder.activeAction = new ActiveAction();
  newOrder.activeAction.lockTime = order.activeAction.lockTime;
  newOrder.activeAction.operation = order.activeAction.operation;

  if (order.strategyId) {
    newOrder.strategyId = order.strategyId;
    useOrderStrategiesStore().addOrderToStrategiesLookup(order.strategyId, newOrder);
  }

  newOrder.exchangeName = exchangeName;
  newOrder.exchangeType = order.exchangeType;

  if (!newOrder.marginAsset) {
    useClientLogsStore().errorLog(`[${exchangeName}] Margin asset should not be empty. Order: ${newOrder.ToString()}`);
  }

  return newOrder;
}

export const useOrdersStore = defineStore('orders', {
  state: (): OrdersState => ({
    exchanges: {},
  }),
  actions: {
    setOrder({ exchangeName, accountId, order }: OrderServerResp) {
      const newOrder = ConvertOrderTypeServer(order, exchangeName, accountId);

      if (newOrder == null) return;

      if (!(exchangeName in this.exchanges)) {
        this.exchanges[exchangeName] = {
          accounts: {},
        };
      }

      if (!(accountId in this.exchanges[exchangeName].accounts)) {
        this.exchanges[exchangeName].accounts[accountId] = {
          orders: {},
        };
      }

      const oldOrder = this.exchanges[exchangeName].accounts[accountId].orders[order.id];

      if (oldOrder) {
        if (oldOrder.strategyId !== newOrder.strategyId) {
          if (!newOrder.strategyId) {
            useOrderStrategiesStore().deleteOrderFromStrategiesLookup(oldOrder.strategyId, oldOrder);
          } else if (!oldOrder.strategyId) {
            useOrderStrategiesStore().addOrderToStrategiesLookup(newOrder.strategyId, newOrder);
          } else {
            useOrderStrategiesStore().deleteOrderFromStrategiesLookup(oldOrder.strategyId, oldOrder);
            useOrderStrategiesStore().addOrderToStrategiesLookup(newOrder.strategyId, newOrder);
          }
        }
      }

      this.exchanges[exchangeName].accounts[accountId].orders[order.id] = newOrder;
    },
    addOrders(body: OrdersServerResp) {
      for (const orderId in body.orders) {
        this.setOrder({
          exchangeName: body.exchangeName,
          accountId: body.accountId,
          order: body.orders[orderId],
        });
      }
    },
    deleteOrder(body: OrderServerResp) {
      const oldOrder = this.exchanges[body.exchangeName]?.accounts[body.accountId]?.orders[body.order.id];

      if (oldOrder) {
        delete this.exchanges[body.exchangeName]?.accounts[body.accountId]?.orders[body.order.id];

        if (oldOrder.strategyId) {
          useOrderStrategiesStore().deleteOrderFromStrategiesLookup(oldOrder.strategyId, oldOrder);
        }
      }
    },
    createOrderHttp(body: OrderType, accountMeta: AccountMeta): Promise<string> {
      const userStore = useUserStore();
      const token = userStore.token;
      const requestInfo = createRequestData('POST', token, body);

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${accountMeta.exchangeName}/accounts/${accountMeta.accountId}/orders`,
        requestInfo,
        'create',
        'order',
      );
    },
    updateOrderHttp(body: OrderType, accountMeta: AccountMeta): Promise<string> {
      const userStore = useUserStore();
      const token = userStore.token;
      const requestInfo = createRequestData('PATCH', token, body);

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${accountMeta.exchangeName}/accounts/`
        + `${accountMeta.accountId}/orders/${body.id}`,
        requestInfo,
        'update',
        'order',
      );
    },
    deleteOrderHttp(body: OrderType, accountMeta: AccountMeta): Promise<string> {
      const userStore = useUserStore();
      const token = userStore.token;
      const requestInfo = createRequestData('DELETE', token, body);

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${accountMeta.exchangeName}/accounts/`
        + `${accountMeta.accountId}/orders/${body.id}`,
        requestInfo,
        'delete',
        'order',
      );
    },
  },
  getters: {
    getOrders: (state) => () => {
      return state.exchanges;
    },
    getOrder: (state) => (orderId: string): OrderType | null => {
      for (const exchangeName in state.exchanges) {
        const accounts = state.exchanges[exchangeName].accounts;

        for (const accountId in accounts) {
          const account = accounts[accountId];

          if (account.orders[orderId]) {
            return account.orders[orderId];
          }
        }
      }

      return null;
    },
  },
});
