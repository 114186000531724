import { AccountType } from '@/types/account';

export enum EXCHANGE_NAME {
  BINANCE = 'Binance',
  BITFINEX = 'Bitfinex',
  BITMEX = 'Bitmex',
  BITSTAMP = 'Bitstamp',
  CAPITAL = 'Capital',
  COINBASE = 'Coinbase',
  DYDX = 'dYdX',
  GATEIO = 'GateIO',
  IG = 'IG',
  KRAKEN = 'Kraken',
  KUCOIN = 'Kucoin',
  POLONIEX = 'Poloniex',
  PROBIT = 'Probit',
  TEST = 'TEST',
}

export enum EXCHANGE_TYPE {
  DERIVATIVES = 'Derivatives',
  SPOT = 'Spot',
}

export enum ENTITY_CATEGORY {
  None = '', // Default for adding an implemented exchange (it must be set otherwise)
  CRYPTO = 'Crypto',
  BROKER = 'Broker',
  BANK = 'Bank',
  PROPERTY = 'Property',
}

export class EntityType {
  public name: string;
  public category: ENTITY_CATEGORY;
  public custom: boolean;
  public subExchanges: Record<string, SubEntityType>;
  public accounts: Record<string, AccountType>;

  constructor(name: string, category: ENTITY_CATEGORY, custom: boolean) {
    this.name = name;
    this.category = category;
    this.custom = custom;
    this.accounts = {};
    this.subExchanges = {};
  }
}

export class SubEntityType {
  public type: string;

  constructor(type: string) {
    this.type = type;
  }
}
