<template>
  <div class="panel">
    <div class="panel__header" :class="{ 'panel__header--active': !collasped }">
      <h3 class="panel__header__title"> {{ props.title }} - {{ positionsView?.length }}</h3>
      <div class="panel__header__button panel__header__button--last">
        <router-link v-if="Object.keys($route.query).length > 0" class="button" :to="{ path: $route.fullPath }">
          Clear Filters
        </router-link>
      </div>
      <i
        :class="{ 'ti-angle-up': !collasped, 'ti-angle-down': collasped }"
        class="panel__header__icon" @click="collasped = !collasped"
      />
    </div>

    <div v-if="!collasped" class="panel__body vtable">
      <div class="vtable-header hand" style="padding-left: 4px;" @click="updateTableData($event)">
        <div class="vtable-header__item vtable__size--m-25 vtable__size--8" name="exchangeName">
          <select v-model="exchangeNameFilter" name="exchangeNameFilter" class="vtable-header__item__input">
            <option value="">EX. NAME</option>
            <option value="" disabled>--</option>
            <option v-for="exchangeName in orderedExchangeNames" :key="exchangeName">{{ exchangeName }}</option>
          </select>
        </div>
        <div class="vtable-header__item vtable__size--m-37 vtable__size--10">
          <div class="vtable-header__input-wrap">
            <input
              v-model="symbolFilterInputFieldOnly" class="vtable-header__item__input-wrap__input"
              type="text" placeholder="SYMBOL NAME" autocomplete="off" @input="debouncedSymbolSearch($event)"
            />
            <i class="ti-arrow-down sort-icon" />
          </div>
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--12" name="price" title="Execution Price">
          Exec. Price <i class="ti-arrow-down sort-icon" />
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--8" name="liquidation">
          Liq. Price <i class="ti-arrow-down sort-icon" />
        </div>

        <div class="vtable-header__item vtable__size--m-hide vtable__size--13" name="lastPrice">
          Price: LP (MP) <i class="ti-arrow-down sort-icon" />
        </div>
        <div
          class="vtable-header__item vtable__size--m-hide vtable__size--13"
          name="distanceLP" title="Distance: LP% (MP%)"
        >
          DIST. LP% (MP%) <i class="ti-arrow-down sort-icon" />
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--6" name="quantity" title="Quantity">
          Qty. <i class="ti-arrow-down sort-icon" />
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--5" name="leverage" title="Leverage">
          Lev. <i class="ti-arrow-down sort-icon" />
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--7">
          <select v-model="marginFilter" class="vtable-header__item__input" name="marginFilter">
            <option value="">MARGIN</option>
            <option value="" disabled>--</option>
            <option v-for="asset in assets" :key="asset">{{ asset }}</option>
          </select>
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--12" name="created">Entered</div>
        <div
          v-if="props.exchangeType !== EXCHANGE_TYPE.SPOT"
          class="vtable-header__item vtable__size--m-30 vtable__size--6"
          name="pNL"
        >
          P&L ({{ choosenAssetSymbol }})
        </div>
        <div class="vtable-header__item vtable__size--m-8 vtable__size--d-hide" />
      </div>
      <DynamicScroller :items="positionsView" :min-item-size="36" class="panel__body__table vtable">
        <template #default="{ item, index, active }">
          <DynamicScrollerItem
            :item="item" :active="active" :size-dependencies="[item.showOverview]" :data-index="index"
          >
            <div
              class="vtable__row"
              :class="item.showOverview ?
                'vtable__row--'+item.position.side+ ' vtable__row--overview-active' :
                'vtable__row--'+item.position.side"
              @click="selectPosition(item.position.id)"
            >
              <div class="vtable__row__item vtable__size--m-25 vtable__size--8">
                {{ item.position.exchangeName }} &nbsp;
                <span
                  v-if="item.position.exchangeType === EXCHANGE_TYPE.DERIVATIVES"
                  :title="item.position.exchangeType"
                >
                  (D)
                </span>
                <span v-else :title="item.position.exchangeType">(S)</span>
              </div>
              <div class="vtable__row__item vtable__size--m-37 vtable__size--10">
                <router-link
                  :title="item.position.symbol"
                  :to="{
                    path: `/exchanges/${item.position.exchangeName}/${item.position.exchangeType}/` +
                      `${encodeURIComponent(item.position.symbol)}/${item.position.accountId}`,
                  }"
                >
                  {{ item.consistentSymbol }}
                </router-link>
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--12">
                {{ formatNumber(item.position.price) }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--8">
                {{ formatNumber(item.position.liquidation) }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--13">
                {{ formatNumber(item.lastPrice) }}
                <span v-if="parseFloat(item.markPrice)"> ({{ formatNumber(item.markPrice) }})</span>
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--13">
                {{ item.distanceLP }}%<span v-if="item.distanceMP"> ({{ item.distanceMP }}%)</span>
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--6">
                {{ formatNumber(item.position.quantity) }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--5">
                {{ item.leverage }}x
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--7">
                {{ formatNumber(item.position.margin) }}
              </div>
              <div class="vtable__row__item vtable__size--m-hide vtable__size--12">
                {{ convertUnixTimestampToDate(item.position.created) }}
              </div>
              <div
                v-if="props.exchangeType !== EXCHANGE_TYPE.SPOT"
                class="vtable__row__item vtable__size--m-30 vtable__size--6"
                :class="pNLBg(item)"
              >
                {{ formatNumber(calculatePNL(item)) }}
              </div>

              <div class="vtable__row__item vtable__row__item--open-btn vtable__size--d-hide vtable__size--m-8">
                <button class="vtable__row__button" @click="item.showOverview = !item.showOverview">
                  <i :class="!item.showOverview ? 'ti-angle-down' : 'ti-angle-up'" />
                </button>
              </div>
              <div v-if="item.showOverview" class="vtable__row__overview">
                <div class="vtable__m-overview">
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Distance: LP% (MP%)</label>
                    <div>{{ item.distanceLP }}%<span v-if="item.distanceMP"> ({{ item.distanceMP }}%)</span></div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Execution Price</label>
                    <div>{{ formatNumber(item.position.price) }}</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Liquidation Price</label>
                    <div>{{ formatNumber(item.position.liquidation) }}</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Price: LP (MP)</label>
                    <div>
                      {{ formatNumber(item.lastPrice) }}
                      <span v-if="parseFloat(item.markPrice)"> ({{ formatNumber(item.markPrice) }})</span>
                    </div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Quantity</label>
                    <div>{{ formatNumber(item.position.quantity) }}</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Leverage</label>
                    <div>{{ item.leverage }}x</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Margin</label>
                    <div>{{ formatNumber(item.position.margin) }}</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Entered</label>
                    <div>{{ convertUnixTimestampToDate(item.position.created) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { useInstrumentsStore } from '@/stores/exchanges/instruments';
import { usePricesStore } from '@/stores/exchanges/prices';
import { usePositionsStore } from '@/stores/exchanges/positions';
import { useUserSettingsStore } from '@/stores/user/settings';
import { useQueryStringStore } from '@/stores/queryString';
import { useAccountManagementStore } from '@/stores/exchanges/accountManagement';
import { useRoute } from 'vue-router';
import { formatNumber, calculateProfit, getDistance } from '@/utilities';
import { EXCHANGE_TYPE } from '@/types/exchange';
import { SIDE } from '@/types/account';
import { PositionType, PositionTypeKeys } from '@/types/positions';
import { OrderProfitType } from '@/types/orders';

class PositionRowView {
  public id: string; // For the virtual scroller (position ID)
  public position: PositionType;
  public lastPrice: string;
  public markPrice: string;
  public distanceLP: number;
  public distanceMP: number;
  public consistentSymbol: string;
  public leverage: string;

  constructor(position: PositionType) {
    this.id = position.id;
    this.position = position;
  }
}

// Store
const userSettingsStore = useUserSettingsStore();
const accountManagementStore = useAccountManagementStore();
const instrumentsStore = useInstrumentsStore();
const pricesStore = usePricesStore();
const positionsStore = usePositionsStore();
const queryStringStore = useQueryStringStore();

const route = useRoute();

// Computed
const instruments = computed(() => instrumentsStore.instruments);
const prices = computed(() => pricesStore.prices);
const orderedExchangeNames = computed(() => accountManagementStore.getOrderedExchangesNames);
const positions = computed(() => {
  const exchangeName = String(route.params.exchangeName || '');
  const exchangeType = String(route.params.exchangeType || '');
  const accountId = String(route.params.accountId || '');

  return positionsStore.getPositions(exchangeName, exchangeType, accountId);
});
const choosenAssetSymbol = computed(() => userSettingsStore.getChosenAssetSymbol);

// Parent properties
const props = withDefaults(defineProps<{
  title: string,
  exchangeType: string, // Adjust the type as needed for WalletType
}>(), {
  title: '',
  exchangeType: '',
});

// Variables
const sortByTH = ref<HTMLElement>(null);
const orderBy = ref('desc');
const collasped = ref(false);
const marginFilter = ref(''); // TODO Watch this to update URL params
const exchangeNameFilter = ref(''); // TODO Watch this to update URL params
const exchangeTypeFilter = ref('');
const sideFilter = ref('');
const symbolFilter = ref('');
const symbolFilterInputFieldOnly = ref('');
const timeout = ref<number | null>(null);
const assets = ref<string[]>([]);
const sortBy = ref<PositionTypeKeys>('created');
const positionsView = ref<PositionRowView[]>([]);

const emit = defineEmits(['selectedPositions']);

// Watchers
watch(() => positionsStore.exchanges, () => {
  positionsView.value = computePositionsData();
}, { deep: true });

watch(exchangeNameFilter, (val: string) => {
  if (val !== route.query.exchangeName as string) {
    queryStringStore.update({ 'exchangeName': val || null });
  }
});

watch(exchangeTypeFilter, (val: string) => {
  if (val !== route.query.exchangeType as string) {
    queryStringStore.update({ 'exchangeType': val || null });
  }
});

watch(() => route.query.exchangeName ? String(route.query.exchangeName) : '', (val: string) => {
  exchangeNameFilter.value = val || '';
  positionsView.value = computePositionsData();
}, { immediate: false });

watch(() => route.query.exchangeType ? String(route.query.exchangeType) : '', (val: string) => {
  exchangeTypeFilter.value = val || '';
  positionsView.value = computePositionsData();
}, { immediate: false });

watch(() => route.query.symbol ? String(route.query.symbol) : '', (val: string) => {
  if (symbolFilter.value != val) {
    symbolFilter.value = val || '';
    positionsView.value = computePositionsData();
  }
}, { immediate: false });

watch(() => route.query.side ? String(route.query.side) : '', (val: string) => {
  sideFilter.value = val || '';
  positionsView.value = computePositionsData();
}, { immediate: false });

// Vue Lifecycle Functions
onMounted(() => {
  exchangeNameFilter.value = route.query.exchangeName as string || '' ;
  exchangeTypeFilter.value = route.query.exchangeType as string || '' ;
  sideFilter.value = route.query.side as string || '' ;
  symbolFilter.value = route.query.symbol as string || '' ;
  symbolFilterInputFieldOnly.value = route.query.symbol as string || '' ;
  positionsView.value = computePositionsData();
});

// Functions
const debouncedSymbolSearch = (event: Event) => {
  if (timeout.value) clearTimeout(timeout.value);
  timeout.value = window.setTimeout(() => {
    const target = event.target as HTMLInputElement;
    if (target.value !== route.query.symbol as string) {
      queryStringStore.update({ 'symbol': target.value || '' });
    }
  }, 500);
};

const updateTableData = (e: Event) => {
  let target = e.target as HTMLElement;

  if (target.classList.contains('vtable-header__input-wrap__input')) {
    return;
  }
  if (target.classList.contains('sort-icon')) {
    target = target.parentNode as HTMLElement;
  }
  if (target.classList.contains('vtable-header__input-wrap')) {
    target = target.parentNode as HTMLElement;
  }

  const name = target.getAttribute('name');

  if (!name || name === 'marginFilter') {
    return;
  }
  if (sortByTH.value) {
    sortByTH.value.classList.remove('asc');
    sortByTH.value.classList.add('desc');
  }

  if (name === sortBy.value) {
    // Update orderBy
    const lastOrderBy = orderBy.value;
    orderBy.value = orderBy.value === 'asc' ? 'desc' : 'asc';

    if (sortByTH.value) {
      sortByTH.value.classList.remove(lastOrderBy);
      sortByTH.value.classList.add(orderBy.value);
    }
  } else {
    // Update sortBy
    sortBy.value = name as PositionTypeKeys;

    if (sortByTH.value) {
      sortByTH.value.classList.remove('desc');
      sortByTH.value.classList.remove('asc');
    }

    sortByTH.value = target;
    orderBy.value = 'asc';
    sortByTH.value.classList.add('asc');
  }
};

const convertUnixTimestampToDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000),
    year = date.getFullYear(),
    month = date.getMonth() + 1,
    day = date.getDate(),
    hours = date.getHours(),
    minutes = `0${date.getMinutes()}`,
    seconds = `0${date.getSeconds()}`,
    formattedDate = `${day}/${month}/${year} ${hours}:${minutes.substr(-2)}:${seconds.substr(-2)}`;

  return formattedDate;
};

const calculatePNL = (positionView: PositionRowView): string => {
  const instrument = instruments.value?.[positionView.position.exchangeName]?.[positionView.position.exchangeType]?.
    [positionView.position.symbol];
  const profitData = new OrderProfitType(
    positionView.position.exchangeType,
    positionView.position.exchangeName,
    instrument,
    Number(positionView.position.price),
    Number(positionView.lastPrice),
    Math.abs(Number(positionView.position.quantity)),
  );
  const profit = calculateProfit(profitData);

  if (positionView.position.side === SIDE.SELL && profitData.limitPrice < profitData.lastPrice) {
    return `-${profit}`;
  }

  if (positionView.position.side == SIDE.BUY && profitData.limitPrice > profitData.lastPrice) {
    return `-${profit}`;
  }

  return profit;
};

const pNLBg = (positionView: PositionRowView): string => {
  return +calculatePNL(positionView) > 0 ? 'greenBg' : 'redBg';
};

const computePositionsData = (): PositionRowView[] => {
  if (!positions.value) {
    return [];
  }

  if (!prices.value) {
    const positionsView: PositionRowView[] = [];

    Object.values(positions.value).forEach((position: PositionType) => {
      positionsView.push(new PositionRowView(position));
    });

    return positionsView;
  }

  const positionsView: PositionRowView[] = [];
  const exchangeNamesMap: Record<string, boolean> = {};
  const assetsMap: Record<string, boolean> = {};

  // Combine prices and positions
  for (const positionId in positions.value) {
    const position = positions.value[positionId],
      exchangeName = position.exchangeName,
      exchangeType = position.exchangeType,
      symbol = position.symbol;

    exchangeNamesMap[exchangeName] = true;
    assetsMap[position.marginAsset] = true;

    // TODO: fairly hacky, should not hardcode this
    if (exchangeType === EXCHANGE_TYPE.SPOT) {
      const d = new Date();
      const pD = d.getDate() - 7;
      const releaseTs = position.created * 1000;

      d.setDate(pD);

      if (releaseTs < +d) {
        continue;
      }
    }

    if (marginFilter.value !== '') {
      if (position.marginAsset !== marginFilter.value) {
        continue;
      }
    }

    if (exchangeNameFilter.value !== '') {
      if (exchangeName !== exchangeNameFilter.value) {
        continue;
      }
    }
    if (exchangeTypeFilter.value !== '') {
      if (exchangeType !== exchangeTypeFilter.value) {
        continue;
      }
    }

    if (sideFilter.value !== '') {
      if (position.side !== sideFilter.value) {
        continue;
      }
    }

    const positionView = new PositionRowView(position);

    // TODO: check if exchangeName in $params?
    if (
      exchangeName in prices.value
        && exchangeType in prices.value[exchangeName]
        && symbol in prices.value[exchangeName][exchangeType]
    ) {
      // TODO: should clone positionData? Otherwise, the actual position object will be updated
      positionView.lastPrice = prices.value[exchangeName][exchangeType][symbol].lastPrice;
      positionView.markPrice = prices.value[exchangeName][exchangeType][symbol].markPrice;
      positionView.distanceLP = getDistance(position.price, positionView.lastPrice, 0);
      positionView.distanceMP = getDistance(position.price, positionView.markPrice, 0);
    }

    const instrument = instruments.value?.[exchangeName]?.[exchangeType]?.[symbol];

    if (instrument) {
      positionView.consistentSymbol = instrument.consistentSymbol;
    } else {
      // console.log(
      //   `UNKNOWN: state.instruments[${exchangeName}][${exchangeType}][${symbol}]`,
      //   this.instruments?.[exchangeName]?.[exchangeType],
      // );
      positionView.consistentSymbol = position.symbol;
    }

    if (symbolFilter.value !== '') {
      if (positionView.consistentSymbol.toLowerCase().indexOf(symbolFilter.value.toLowerCase()) === -1) {
        continue;
      }
    }

    if (position.leverage === '0') {
      if (instrument) {
        positionView.leverage = instrument.leverage;
      } else {
        positionView.leverage = '0';
      }
    }

    positionsView.push(positionView);
  }

  assets.value = Object.keys(assetsMap);
  assets.value.sort((a, b) => a.localeCompare(b));

  // Sort by selected column
  if (orderBy.value === 'asc') {
    positionsView.sort(
      (a,b) => (
        a.position[sortBy.value] > b.position[sortBy.value]) ?
        1 : ((b.position[sortBy.value] > a.position[sortBy.value]) ? -1 : 0),
    );
  } else {
    positionsView.sort(
      (a,b) => (
        a.position[sortBy.value] < b.position[sortBy.value]) ?
        1 : ((b.position[sortBy.value] < a.position[sortBy.value]) ? -1 : 0),
    );
  }

  const selectedPositions: Record<string, PositionType> = {};

  positionsView.forEach(positionView => {
    // Still only filter out positions that are relevant to this pair
    if (positionView.position.exchangeName !== route.params.exchangeName ||
        positionView.position.exchangeType !== route.params.exchangeType ||
        positionView.position.symbol !== route.params.symbol) {
      return;
    }

    selectedPositions[positionView.position.id] = positionView.position;
  });

  emit('selectedPositions', selectedPositions);

  return positionsView;
};

const selectPosition = (positionId: string) => {
  const selectedPosition = route.query.selectedPosition as string || '';

  if (!selectedPosition || selectedPosition != positionId) {
    queryStringStore.update({
      'selectedPosition': positionId,
      'selectedOrder': '',
    });
  } else {
    queryStringStore.update({ 'selectedPosition': '' });
  }
};
</script>

<style lang="scss" scoped></style>
