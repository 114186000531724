import { defineStore } from 'pinia';
import Config from '@/config';
import { parsePriceData } from '@/stores/exchanges/pricedata';
import { PriceDataServer } from '@/types/pricedata';
import { TradeActivityType, IgState, IgTradeServerResp, TradeActivityTypeServer } from '@/types/ig';
import { useUserStore } from '@/stores/user/user';
import { createRequestData, performHttpRequest } from '@/utilities';
import { AccountMeta } from '@/types/orders';

function convertTradeActivityTypesServer(trades: TradeActivityTypeServer[]): TradeActivityType[] {
  const newTrades: TradeActivityType[] = [];

  for (const trade of trades) {
    const newTrade = convertTradeActivityTypeServer(trade);
    newTrades.push(newTrade);
  }

  return newTrades;
}

function convertTradeActivityTypeServer(trade: TradeActivityTypeServer): TradeActivityType {
  const newTrade = new TradeActivityType();

  newTrade.date = trade.date;
  newTrade.epic = trade.epic;
  newTrade.period = trade.period;
  newTrade.dealId = trade.dealId;
  newTrade.type = trade.type;
  newTrade.status = trade.status;
  newTrade.details = trade.details;
  newTrade.summary = trade.summary;

  return newTrade;
}

export const useIgStore = defineStore('ig', {
  state: (): IgState => ({
    trades: new Map<string, TradeActivityType[]>(),
    priceData: null,
  }),
  actions: {
    setTrades({ trades, page }: IgTradeServerResp) {
      if (page === 0) {
        this.trades = new Map<string, TradeActivityType[]>();
      }

      for (const market of Object.keys(trades)) {
        if (!this.trades.has(market)) {
          this.trades.set(market, []);
        }

        const marketTrades = this.trades.get(market) as TradeActivityType[];
        const newMarketTrades = convertTradeActivityTypesServer(trades[market]);
        marketTrades.push(...newMarketTrades);
      }
    },
    setPriceData(priceData: PriceDataServer) {
      this.priceData = parsePriceData(priceData);
    },
    resetPriceData() {
      this.priceData = null;
    },
    fetchIgAccountActivity(accountMeta: AccountMeta, fromUnixTs: number, toUnixTs: number): Promise<string> {
      const userStore = useUserStore();
      const token = userStore.token;
      const requestInfo = createRequestData('GET', token, '');

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${accountMeta.exchangeName}/accounts/`
        + `${accountMeta.accountId}/activity?from=${fromUnixTs}&to=${toUnixTs}`,
        requestInfo,
        'receive',
        'activity',
      );
    },
    fetchIgPriceData(accountMeta: AccountMeta, fromUnixTs: number, toUnixTs: number, market: string): Promise<string> {
      const userStore = useUserStore();
      const token = userStore.token;
      const requestInfo = createRequestData('GET', token, '');

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${accountMeta.exchangeName}/exchangeTypes/${accountMeta.exchangeType}/`
        + `igpricedata?from=${fromUnixTs}&to=${toUnixTs}&market=${market}`,
        requestInfo,
        'receive',
        'price data',
      );
    },
  },
});
