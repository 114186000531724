import { OrderType } from '@/types/orders';
import { PositionType } from '@/types/positions';
import { PriceData } from '@/types/pricedata';

export class TradeActivityType {
  public date: string;
  public epic: string;
  public period: string;
  public dealId: string;
  public type: string;
  public status: string;
  public details: ActivityDetails | null;
  public summary: string;

  constructor() {
    this.date = '';
    this.epic = '';
    this.period = '';
    this.dealId = '';
    this.type = '';
    this.status = '';
    this.details = null;
    this.summary = '';
  }
}

class ActivityDetails {
  dealReference: string;
  actions: ActivityDetailAction[];
  marketName: string;
  goodTillDate: string;
  currency: string;
  size: string;
  direction: string;
  level: string;
  stopLevel: string;
  limitLevel: string;

  constructor() {
    this.dealReference = '';
    this.actions = [];
    this.marketName = '';
    this.goodTillDate = '';
    this.currency = '';
    this.size = '';
    this.direction = '';
    this.level = '';
    this.stopLevel = '';
    this.limitLevel = '';
  }
}

class ActivityDetailAction {
  actionType: string;
  affectedDealId: string;

  constructor() {
    this.actionType = '';
    this.affectedDealId = '';
  }
}

export class IgMarketState {
  public ordersOpen: Map<string, OrderType> = new Map<string, OrderType>();
  public ordersClosed: Map<string, OrderType> = new Map<string, OrderType>();
  public positionsOpen: Map<string, PositionType> = new Map<string, PositionType>();
  public positionsClosed: Map<string, PositionType> = new Map<string, PositionType>();

  public ToString(): string {
    let ret = 'Orders:\n\tOpen:\n';

    for (const [id, order] of this.ordersOpen) {
      ret += `\t\t${id}: ${order.ToString()}\n`;
    }

    ret += '\tClosed:\n';

    for (const [id, order] of this.ordersClosed) {
      ret += `\t\t${id}: ${order.ToString()}\n`;
    }

    ret += 'Positions:\n\tOpen:\n';

    for (const [id, position] of this.positionsOpen) {
      ret += `\t\t${id}: ${position.ToString()}\n`;
    }

    ret += '\tClosed:\n';

    for (const [id, position] of this.positionsClosed) {
      ret += `\t\t${id}: ${position.ToString()}\n`;
    }

    return ret;
  }
}

export interface IgState {
  trades: Map<string, TradeActivityType[]>;
  priceData: PriceData;
}

export interface IgTradeServerResp {
  trades: Record<string, TradeActivityTypeServer[]>;
  page: number;
}

export interface TradeActivityTypeServer {
  date: string;
  epic: string;
  period: string;
  dealId: string;
  type: string;
  status: string;
  details: ActivityDetailsServer | null;
  summary: string;
}

interface ActivityDetailsServer {
  dealReference: string;
  actions: ActivityDetailActionServer[];
  marketName: string;
  goodTillDate: string;
  currency: string;
  size: string;
  direction: string;
  level: string;
  stopLevel: string;
  limitLevel: string;
}

interface ActivityDetailActionServer {
  actionType: string;
  affectedDealId: string;
}
