<template>
  <div class="exchanges">
    <section v-for="(exchange, i) in activeExchanges" :key="i" class="exchange">
      <header class="exchange__header">
        <img :title="exchange.name" :src="`/logos/${exchange.name.toLowerCase()}_logo.svg`" />
      </header>
      <div v-if="Object.values(exchange.accounts).length > 0 " class="exchange__accounts">
        <section
          v-for="account, j in exchange.accounts" :key="j"
          class="exchange__accounts__item" :class="{'bg-red-600': checkPwExpired(account.apiKey?.createdTs || 0) }"
        >
          <button class="">
            <i
              v-if="checkPwExpired(account.apiKey?.createdTs || 0)"
              class="ti-alert text-lg" title="API Key Expired"
            />
          </button>

          <p class="">{{ account.name }}</p>
          <p style="margin-left: auto">{{ account.type }}</p>

          <button
            v-if="exchange.custom"
            :title="account.apiKey?.name"
            @click="toggleSetWalletModal(exchange, account, null)"
          >
            <i class="ti-plus" />
            <i class="ti-wallet" />
          </button>

          <button class="">
            <i
              class="ti-pencil" :title="account.apiKey?.name" @click="toggleUpdateAccountModal(exchange, account)"
            />
          </button>
          <button class="">
            <i class="ti-trash" title="Remove account" @click="deleteAccount(exchange.name, account.id)" />
          </button>
          Wallets:
          <ul>
            <li
              v-for="wallet, k in useWalletsStore().getAccountWallets(account.id)" :key="k" style="display:block"
            >
              <span :title="wallet.id">{{ wallet.name }} (asset count: {{ Object.keys(wallet.balances).length }})</span>
              <button @click="toggleSetWalletModal(exchange, account, wallet)">
                <i class="ti-pencil" />
                <i class="ti-wallet" />
              </button>
              <button v-if="exchange.custom" title="Remove wallet" @click="deleteWallet(exchange, account, wallet)">
                <i class="ti-trash" />
                <i class="ti-wallet" />
              </button>
            </li>
          </ul>
        </section>
      </div>
      <footer class="exchange__footer">
        <button class="button" @click="toggleAddAccountModal(exchange)">
          <i class="ti-plus" /> Add Account
        </button>
        <button
          v-if="Object.values(exchange.accounts).length == 0 " class="button" @click="deleteExchange(exchange.name)"
        >
          <i class="ti-trash" /> Remove Exchange
        </button>
      </footer>
    </section>
    <section v-for="name, i in inactiveExchanges" :key="i" class="exchange exchange--inactive">
      <header class="exchange__header">
        <img :title="name" :src="`/logos/${name.toLowerCase()}_logo.svg`" />
      </header>
      <button class="button" @click="addEntity(name)">
        Activate Exchange
      </button>
    </section>
    <section>
      <header class="exchange__header">
        Custom Entity
      </header>
      <input v-model="entityName" class="sidebar__setting__input" type="text" placeholder="Entity name..." />
      <br />
      <select v-model="entityCategory" class="sidebar__setting__input">
        <option value="" disabled>
          Select Category
        </option>
        <option v-for="category in Object.values(ENTITY_CATEGORY)" :key="category" :value="category">
          {{ category }}
        </option>
      </select>
      <button class="button" @click="addEntity('Other', true)">
        Add Entity
      </button>
    </section>
  </div>

  <teleport to="body">
    <ModalPopup v-if="updateAccountModal.toggle" title="Update Account" @close-modal="toggleUpdateAccountModal">
      <p v-if="updateAccountModal.error" class="modal-form__error">
        {{ updateAccountModal.error }}
      </p>
      <div class="modal-form__item">
        <label for="exchangeName">Exchange Name</label>
        <input id="exchangeName" v-model="updateAccountModal.entity.name" disabled />
      </div>
      <div class="modal-form__item">
        <label for="accountName">Account Name</label>
        <input id="accountName" v-model="updateAccountObj.name" />
      </div>
      <div v-if="!updateAccountModal.entity.custom" class="modal-form__item">
        <label for="exchangeType">Type</label>
        <select id="exchangeType" v-model="updateAccountObj.type">
          <option v-for="validExchangeType, i in updateAccountModal.validTypes" :key="i" :value="validExchangeType">
            {{ validExchangeType }}
          </option>
        </select>
      </div>
      <button
        class="modal-form__button button" @click="updateAccount(updateAccountObj)"
      >
        Update
      </button>
    </ModalPopup>

    <ModalPopup v-if="addAccountModal.toggle" title="Add New Account" @close-modal="toggleAddAccountModal">
      <p v-if="addAccountModal.error" class="modal-form__error">
        {{ addAccountModal.error }}
      </p>
      <div class="modal-form__item">
        <label for="exchangeName">Exchange Name</label>
        <input id="exchangeName" v-model="addAccountModal.entity.name" disabled />
      </div>
      <div class="modal-form__item">
        <label for="accountName">Account Name</label>
        <input id="accountName" v-model="addAccountObj.name" />
      </div>
      <div v-if="!addAccountModal.entity.custom">
        <div class="modal-form__item">
          <label for="exchangeType">Type</label>
          <select id="exchangeType" v-model="addAccountObj.type">
            <option v-for="validExchangeType, i in addAccountModal.validTypes" :key="i" :value="validExchangeType">
              {{ validExchangeType }}
            </option>
          </select>
        </div>
        <div class="modal-form__item">
          <label for="apiKey">API Key</label>
          <input id="apiKey" v-model="addAccountObj.apiKey.key" />
        </div>
        <div
          v-if="![EXCHANGE_NAME.IG as string, EXCHANGE_NAME.CAPITAL as string].includes(addAccountModal.entity.name)"
          class="modal-form__item"
        >
          <label for="secret">API Secret</label>
          <input id="secret" v-model="addAccountObj.apiKey.secret" />
        </div>
        <div
          v-if="[EXCHANGE_NAME.KUCOIN as string, EXCHANGE_NAME.DYDX as string].includes(addAccountModal.entity.name)"
          class="modal-form__item"
        >
          <label for="passphrase">API Passphrase</label>
          <input id="passphrase" v-model="addAccountObj.apiKey.passphrase" />
        </div>
        <div
          v-if="[EXCHANGE_NAME.IG as string, EXCHANGE_NAME.CAPITAL as string].includes(addAccountModal.entity.name)"
          class="modal-form__item"
        >
          <label for="username">Username</label>
          <input id="username" v-model="addAccountObj.apiKey.username" />
        </div>
        <div
          v-if="[EXCHANGE_NAME.IG as string, EXCHANGE_NAME.CAPITAL as string].includes(addAccountModal.entity.name)"
          class="modal-form__item"
        >
          <label for="password">Password</label>
          <input id="password" v-model="addAccountObj.apiKey.password" />
        </div>
        <div v-if="addAccountModal.entity.name === EXCHANGE_NAME.IG" class="modal-form__item">
          <label for="accountId">Account ID</label>
          <input id="accountId" v-model="addAccountObj.apiKey.accountId" />
        </div>
      </div>

      <button class="modal-form__button button" @click="addAccount(addAccountObj)">
        Add
      </button>
    </ModalPopup>

    <ModalPopup v-if="setWalletModal.toggle" title="Add Wallet" @close-modal="toggleSetWalletModal">
      <p v-if="setWalletModal.error" class="modal-form__error">
        {{ setWalletModal.error }}
      </p>
      <div class="modal-form__item">
        <label for="exchangeName">Exchange Name</label>
        <input id="exchangeName" v-model="setWalletModal.entity.name" disabled />
      </div>
      <div class="modal-form__item">
        <label for="accountName">Account Name</label>
        <input id="accountName" v-model="setWalletModal.account.name" disabled />
      </div>
      <div class="modal-form__item">
        <label for="walletName">Wallet Name</label>
        <input id="walletName" v-model="setWalletModal.name" />
      </div>
      <div class="modal-form__item">
        <label for="exchangeType">Asset list</label>
        <ul>
          <li v-for="assetAmount, assetName in setWalletModal.assets" :key="assetName" :value="assetAmount">
            {{ assetName }}: {{ assetAmount }}
            <button v-if="setWalletModal.entity.custom" @click="removeWalletAsset(assetName)">(X)</button>
          </li>
        </ul>
      </div>
      <div v-if="setWalletModal.entity.custom" class="modal-form__item">
        <input id="assetName" v-model="setWalletModal.assetNameTmp" placeholder="Asset name..." />
        <input id="assetAmount" v-model="setWalletModal.assetAmountTmp" placeholder="Asset amount..." />
        <button class="modal-form__button button" @click="addWalletAsset()">Add asset</button>
      </div>
      <div>
        <button v-if="setWalletModal.newWallet" class="modal-form__button button" @click="addWallet()">Add</button>
        <button v-else class="modal-form__button button" @click="updateWallet()">Update</button>
      </div>
    </ModalPopup>
  </teleport>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useAccountManagementStore } from '@/stores/exchanges/accountManagement';
import { useWalletsStore } from '@/stores/exchanges/wallets';
import { useUserStore } from '@/stores/user/user';
import ModalPopup from '@/layout/modal/ModalPopup.vue';
import { checkPwExpired } from '@/utilities';
import {
  AccountType, AccountTypeClientReq, AccountApiKeyClientReq, AccountClientReq,
} from '@/types/account';
import { EXCHANGE_NAME, EXCHANGE_TYPE, ENTITY_CATEGORY, EntityType } from '@/types/exchange';
import { RespError } from '@/types/general';
import { useClientLogsStore } from '@/stores/user/clientLogs';
import { AssetBalanceType, WalletType } from '@/types/wallets';

// Stores
const accountManagementStore = useAccountManagementStore();
const userStore = useUserStore();
const clientLogsStore = useClientLogsStore();

const entityName = ref('');
const entityCategory = ref(ENTITY_CATEGORY.None);

// Computed Properties
const userToken = computed(() => userStore.token);
const activeExchanges = computed<Record<string, EntityType>>(() => {
  const exchanges = accountManagementStore.getExchanges;

  const ordered = Object.keys(exchanges)
    .sort((a: string, b: string) => {
      const fa = a.toLowerCase(), fb = b.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    })
    .reduce((obj: Record<string, EntityType>, key: string) => {
      obj[key] = exchanges[key];
      return obj;
    }, {});

  return ordered;
});
const allExchanges = computed(() => accountManagementStore.getValidExchangeNames);
const inactiveExchanges = computed<string[]>((): string[] => {
  const exchangesNames = accountManagementStore.getOrderedExchangesNames;
  const map: Record<string, boolean>  = {};

  exchangesNames.map(x => { map[x] = true; });

  return Object.keys(allExchanges.value).filter(x => !(x in map)).sort((a, b) => {
    return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
  });
});

// Properties
class AccountModal {
  public toggle: boolean;
  public error: string;
  public entity: EntityType;
  public validTypes: string[];

  constructor(toggle: boolean, error: string, validTypes: string[]) {
    this.toggle = toggle;
    this.error = error;
    this.validTypes = validTypes;
  }
}

class WalletModal {
  public toggle: boolean = false;
  public error: string = '';
  public newWallet: boolean = true;

  public id: string = '';
  public name: string = '';
  public entity: EntityType;
  public assetNameTmp: string = '';
  public assetAmountTmp: string = '';
  public account: AccountType;
  public assets: Record<string, string> = {};
}

const validTypes = ['All', EXCHANGE_TYPE.SPOT as string, EXCHANGE_TYPE.DERIVATIVES as string];
const addAccountModal = ref(new AccountModal(false, '', validTypes));
const setWalletModal = ref(new WalletModal());
const addAccountObj = ref(new AccountTypeClientReq('', '', '', new AccountApiKeyClientReq('', '', '', '', '', '')));
const updateAccountModal = ref(new AccountModal(false, '', validTypes));
const updateAccountObj = ref(new AccountTypeClientReq('', '', '' , null));

// Methods
const toggleAddAccountModal = (entity: EntityType) => {
  addAccountModal.value.toggle = !addAccountModal.value.toggle;
  if (addAccountModal.value.toggle) {
    addAccountModal.value.entity = entity;
    addAccountModal.value.validTypes = setSelectorVaildTypes(entity.name);
    addAccountModal.value.error = '';
    addAccountObj.value = new AccountTypeClientReq('', '', '', new AccountApiKeyClientReq('', '', '', '', '', ''));
  }
};

const toggleUpdateAccountModal = (entity: EntityType, account: AccountType) => {
  updateAccountModal.value.toggle = !updateAccountModal.value.toggle;
  if (updateAccountModal.value.toggle) {
    updateAccountModal.value.entity = entity;
    updateAccountModal.value.validTypes = setSelectorVaildTypes(entity.name);
    updateAccountObj.value.name = account.name;
    updateAccountObj.value.id = account.id;
    updateAccountObj.value.type = account.type;
    updateAccountModal.value.error = '';
  }
};

const toggleSetWalletModal = (entity: EntityType, account: AccountType, wallet: WalletType | null) => {
  setWalletModal.value.toggle = !setWalletModal.value.toggle;

  if (setWalletModal.value.toggle) {
    setWalletModal.value.entity = entity;
    setWalletModal.value.account = account;
    setWalletModal.value.error = '';
    setWalletModal.value.newWallet = true;
    setWalletModal.value.assets = {};

    if (wallet !== null) {
      setWalletModal.value.newWallet = false;
      setWalletModal.value.id = wallet.id;
      setWalletModal.value.name = wallet.name;

      for (const assetName in wallet.balances) {
        setWalletModal.value.assets[assetName] = wallet.balances[assetName].total;
      }
    }
  }
};

const addWalletAsset = () => {
  if (setWalletModal.value.assetNameTmp === '' || setWalletModal.value.assetAmountTmp === '') {
    return;
  }

  setWalletModal.value.assets[setWalletModal.value.assetNameTmp] = setWalletModal.value.assetAmountTmp;
  setWalletModal.value.assetNameTmp = '';
  setWalletModal.value.assetAmountTmp = '';
};

const removeWalletAsset = (assetName: string) => {
  delete(setWalletModal.value.assets[assetName]);
};

const addWallet = () => {
  const assets: Record<string, AssetBalanceType> = {};

  for (const assetName in setWalletModal.value.assets) {
    assets[assetName] = new AssetBalanceType(
      setWalletModal.value.assets[assetName],
      setWalletModal.value.assets[assetName],
    );
  }

  accountManagementStore.addEntityWallet(
    userToken.value,
    setWalletModal.value.entity.name,
    setWalletModal.value.account.id,
    new WalletType(
      '',
      setWalletModal.value.name,
      assets,
      setWalletModal.value.entity.name,
      setWalletModal.value.account.id,
    ),
  ).then(() => {
    setWalletModal.value = new WalletModal();
  }).catch((err: string) => {
    setWalletModal.value.error = err;
  });
};

const updateWallet = () => {
  const assets: Record<string, AssetBalanceType> = {};

  if (setWalletModal.value.entity.custom) {
    for (const assetName in setWalletModal.value.assets) {
      assets[assetName] = new AssetBalanceType(
        setWalletModal.value.assets[assetName],
        setWalletModal.value.assets[assetName],
      );
    }
  }

  accountManagementStore.updateEntityWallet(
    userToken.value,
    setWalletModal.value.entity.name,
    setWalletModal.value.account.id,
    new WalletType(
      setWalletModal.value.id,
      setWalletModal.value.name,
      assets,
      setWalletModal.value.entity.name,
      setWalletModal.value.account.id,
    ),
  ).then(() => {
    setWalletModal.value = new WalletModal();
  }).catch((err: string) => {
    setWalletModal.value.error = err;
  });
};

const deleteWallet = (entity: EntityType, account: AccountType, wallet: WalletType) => {
  const msg =`
      Are you sure you want to delete '${wallet.name}' from account '${account.name}' in entity '${entity.name}'?
      This cannot be undone.
    `;

  if (!confirm(msg)) {
    return;
  }

  accountManagementStore.deleteEntityWallet(userToken.value, entity.name, account.id, wallet);
};

const setSelectorVaildTypes = (exchangeName: string) => {
  const types = [];

  if (exchangeName in allExchanges.value) { // Custom entities are not here
    if (allExchanges.value[exchangeName].All) types.push('All');
    if (allExchanges.value[exchangeName].Derivatives) types.push(EXCHANGE_TYPE.DERIVATIVES as string);
    if (allExchanges.value[exchangeName].Spot) types.push(EXCHANGE_TYPE.SPOT as string);
  } else {
    types.push('All');
  }

  return types;
};

const addEntity = (name: string, customEntity = false) => {
  if (customEntity) {
    name = entityName.value;
  }

  const createData = {
    userToken: userToken.value,
    exchange: new EntityType(name, entityCategory.value, customEntity),
  };

  accountManagementStore.addEntity(createData);
};

const deleteExchange = (exchangeName: string) => {
  const msg = `
      Are you sure you want to delete ${exchangeName}?
      This cannot be undone and will delete all ${exchangeName} accounts and API keys.
    `;
  const result = confirm(msg);
  if (result) {
    clientLogsStore.errorLog(`[${exchangeName}] TODO implement deleting exchange safely`);
  }
};

const deleteAccount = (exchangeName: string, id: string) => {
  const msg =`
      Are you sure you want to delete ${exchangeName} account?
      This cannot be undone and will delete all data in ${exchangeName} account including API keys.
    `;
  const result = confirm(msg);
  if (result) {
    const deleteData = new AccountClientReq(
      userToken.value, id, exchangeName, new AccountTypeClientReq(id, '', '', null));
    void accountManagementStore.deleteAccount(deleteData);
  }
};

const addAccount = (account: AccountTypeClientReq) => {
  const createData = new AccountClientReq(userToken.value, '', addAccountModal.value.entity.name, account);
  accountManagementStore.addAccount(createData)
    .then((x) => {
      if (x.status === 200) {
        addAccountModal.value.toggle = false;
      }
    })
    .catch((error: unknown) => {
      const typedError = error as RespError;
      addAccountModal.value.error = typedError.error;
    });
};

const updateAccount = (account: AccountTypeClientReq) => {
  const updateData = new AccountClientReq(userToken.value, account.id, updateAccountModal.value.entity.name, account);
  accountManagementStore.updateAccount(updateData)
    .then((x) => {
      if (x.status === 200) {
        updateAccountModal.value.toggle = false;
      }
    })
    .catch((error: unknown) => {
      const typedError = error as RespError;
      updateAccountModal.value.error = typedError.error;
    });
};
</script>

<style lang="scss" scoped>
.exchanges {
  display: flex;
  flex-wrap: wrap;
}
.button {
  width: auto;
  padding: .5rem;
  font-size: 14px;
  margin: 0;
  width: 100%;
}
.exchange {
  margin: .5rem;
  border: 1px solid var(--panel-border);
  background: var(--panel-bg);
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: $mobile-menu-breakpoint)  {
    width: calc(33.333% - 1rem);
  }

  &--inactive {
    opacity: .35;
    transition: opacity .25s ease;
    &:hover {
      opacity: 1;
    }

    img {
      filter: grayscale(100);
    }
  }

  &__header {
    background: var(--panel-header-bg);
    color: var(--panel-header-color);
    display: flex;
    align-items: center;
    padding: 1.5rem .5rem;

    img {
      max-height: 30px;
      margin: auto;
      filter: brightness(0) invert(1);

      &:hover {
        filter: none;
      }
    }

  }
  &__footer {
    margin-top: auto;
    padding: 1rem 0 0;
    display: flex;
  }

  &__accounts {
    &__item {
      font-size: 13px;
      border-bottom: 1px solid var(--panel-border);
      display: flex;
      justify-content: space-between;

      p {
        padding: .5rem;
      }
      button {
        padding: .5rem;

        &:hover, &:hover i {
          color: var(--brand-primary);
        }
      }
    }
  }
}
</style>
