import { currencyFormatter } from '@/utilities';
import { InstrumentType } from './instruments';
import { SIDE } from '@/types/account';


export class OrderProfitType {
  exchangeType: string;
  exchangeName: string;
  instrument: InstrumentType;
  limitPrice: number;
  lastPrice: number;
  quantity: number;

  constructor(
    exchangeType: string, exchangeName: string, instrument: InstrumentType,
    limitPrice: number, lastPrice: number, quantity: number,
  ) {
    this.exchangeType = exchangeType;
    this.exchangeName = exchangeName;
    this.instrument = instrument;
    this.limitPrice = limitPrice;
    this.lastPrice = lastPrice;
    this.quantity = quantity;
  }
}

export enum ORDER_TYPE {
  LIMIT = 'Limit',
  MARKET = 'Market',
  STOP_LIMIT = 'StopLimit',
  STOP_MARKET = 'StopMarket',
  UNKNOWN = 'Unknown',
}

export enum ORDER_CATEGORY {
  NONE = '',
  LIQUIDITY = 'Liquidity',
  TRADING = 'Trading',
  INVESTMENT = 'Investment',
  PLAIN = 'Plain',
}

export enum ORDER_STATUS {
  UNFILLED = 'UnfilledOrder',
  PARTIALLY_FILLED = 'PartiallyFilledOrder',
  FILLED = 'FilledOrder',
  CANCELLED = 'CancelledOrder',
  FAILED = 'failed_order', // TODO: server uses FAILED_ORDER rather than FailedOrder /o\
  EXPIRED = 'ExpiredOrder',
  UNKNOWN = 'UnknownStatusOrder',
  INACTIVE_UNKNOWN = 'InactiveUnknownStatusOrder',
}

export enum ORDER_TIME_IN_FORCE {
  GTC = -1
}

export const orderCategories = [
  ORDER_CATEGORY.PLAIN, ORDER_CATEGORY.LIQUIDITY, ORDER_CATEGORY.TRADING, ORDER_CATEGORY.INVESTMENT,
];

export interface UpdateLeverage {
  accountMeta: AccountMeta;
  symbol: string;
  leverage: string;
}

export interface UpdateLeverageData {
  leverageData: UpdateLeverage;
  userToken: string;
}

export class AccountMeta {
  accountId: string;
  exchangeName: string;
  exchangeType: string;

  constructor(accountId: string, exchangeName: string, exchangeType: string) {
    this.accountId = accountId;
    this.exchangeName = exchangeName;
    this.exchangeType = exchangeType;
  }
}

export class OrderFlags {
  public timeInForce: number;
  public postOnly: boolean;
  public reduceOnly: boolean;
  public hidden: boolean;

  constructor(
    timeInForce: number = ORDER_TIME_IN_FORCE.GTC,
    postOnly: boolean = true,
    reduceOnly: boolean = false,
    hidden: boolean = false,
  ) {
    this.timeInForce = timeInForce;
    this.postOnly = postOnly;
    this.reduceOnly = reduceOnly;
    this.hidden = hidden;
  }
}

export type OrderTypeKeys = keyof OrderType;

export class ActiveAction {
  public lockTime: number = 0;
  public operation: string = '';

  public lock(operation: string): void {
    this.lockTime = Date.now();
    this.operation = operation;
  }

  public unlock(): void {
    this.lockTime = 0;
    this.operation = '';
  }

  public isLocked(): boolean {
    return this.lockTime !== 0;
  }
}

export class OrderType {
  public id: string = '';
  public exchangeId: string = '';
  public clientId: string = '';
  public symbol: string = '';
  public created: number = 0; // Time of creation (milliseconds timestamp)
  public updated: number = 0; // Last updated (milliseconds timestamp)
  public quantity: string = '0';
  public filledQuantity: string = '0';
  public displayQuantity: string = '0';
  public margin: string = '0';
  public marginAsset: string = '';
  public price: string = '';
  public stopLossPrice: string = '';
  public profitTargetPrice: string = '';
  public leverage: string = '';
  public flags: OrderFlags = null;
  public status: ORDER_STATUS; // ACTIVE, EXECUTED @ PRICE(AMOUNT) e.g. "EXECUTED @ 107.6(-0.2)", ...
  public side: SIDE; // buy, sell
  public type: string; // LIMIT, EXCHANGE LIMIT, MARKET, EXCHANGE MARKET, STOP, ...
  public category: string; // plain, liquidity, trade
  public activeAction: ActiveAction;
  public strategyId: string = '';

  // Auxiliary properties for presentation
  public exchangeName: string;
  public exchangeType: string;
  public consistentSymbol: string;
  public chosenAssetProfit: string;
  public accountId: string;
  public computed: boolean;
  public calculatePnLInternal:
    null | ((fromPrice: string, toPrice: string, quantity: string, side: SIDE) => string);
  public dpFormat = 2;
  public currency = '';

  // TODO move into orderview
  public lastPrice: string;
  public markPrice: string;
  public crossMargin: boolean;
  public writeType: string;
  public distanceLP: number;
  public distanceMP: number;
  public remainingQuantity: string;
  public quoteVolume: string;

  constructor(id: string = '') {
    this.id = id;
  }

  public ToString(): string {
    return `${this.side} ${this.quantity} @ ${this.price} (stop = '${this.stopLossPrice}', ` +
      `target = '${this.profitTargetPrice}', status = '${this.status}', id = '${this.id}')`;
  }

  public calculatePnL(fromPrice: string, toPrice: string, quantity: string): string {
    if (this.calculatePnLInternal === null) {
      return 'NaN'; // Not implemented value
    }

    return this.calculatePnLInternal(fromPrice, toPrice, quantity, this.side);
  }

  public formatAmount(amount: string): string {
    return currencyFormatter(+amount, this.dpFormat, this.currency);
  }

  public clone(): OrderType {
    // Object.create() only does a shallow clone
    const orderClone = Object.assign(new OrderType(this.id), this as OrderType);

    orderClone.activeAction = Object.assign(new ActiveAction(), this.activeAction);
    orderClone.flags = Object.assign(new OrderFlags(), orderClone.flags);

    return orderClone;
  }
}

interface OrderFlagsServer {
  timeInForce: number
  postOnly: boolean
  reduceOnly: boolean
  hidden: boolean
}

export interface OrdersState {
  exchanges: Record<string, { accounts: Record<string, { orders: Record<string, OrderType> }> }>;
}

export interface OrdersServerResp {
  exchangeName: string;
  accountId: string;
  orders: Record<string, OrderTypeServer>;
}

export interface OrderServerResp {
  exchangeName: string;
  accountId: string;
  order: OrderTypeServer;
}

export interface OrderTypeServer {
  id: string;
  exchangeId: string;
  clientId: string;
  symbol: string;
  created: number;
  updated: number;
  quantity: string;
  filledQuantity: string;
  displayQuantity: string;
  margin: string;
  marginAsset: string;
  price: string;
  stopLossPrice: string;
  profitTargetPrice: string;
  leverage: string;
  flags: OrderFlagsServer;
  status: string;
  side: string;
  type: string;
  exchangeType: string;
  category: string;
  activeAction: ActiveAction;
  strategyId: string;
}
